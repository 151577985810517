import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdvanceEditorCompany from "../../advance-editor/company/AdvanceEditorCompany";
import AdvanceKeyword from "../../advance-editor/keyword/AdvanceKeyword";
import getAllWordWithOperator from "../../advance-editor/keyword/getAllWordWithOperator";
import logicToSeperateString from "../../advance-editor/keyword/logicToSeperateString";
import AutoRichEditor from "../../AutoBuildModule/pages/auto-enrich-editor/AutoRichEditor";
import AutoBuildHome from "../../AutoBuildModule/pages/AutoBuildHome";
import EditorView from "../../editor/EditorView";
import removeAllSpaces from "../../editor/removeAllSpaces";
import replaceNewLine from "../../editor/replaceNewLine";
import replaceSmallAndOrNot from "../../editor/replaceSmallAndOrNot";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import globalRequest from "../../GlobalModule/globalRequest";
import TranslateYourString from "../../google-translation/TranslateYourString";
import { ADVANCE_KEYWORD_ID, AUTO_BUILD, NEW_API, NEW_MODULE_API, STORAGE, TEXT_INPUT } from "../../Helpers/Enums";
import { KEYWORD_VIEW_TYPE, MANAGE_TEAM, REQUEST } from "../../Helpers/Enums";
// import images
import booAddSqaud1Img from "../../image-assets/boo-add-sqaud1.png";
import dropArrowIconngreen from "../../image-assets/circle-chevron-top.svg";
import deconstructViewEye from "../../image-assets/deconstruct-view-eye.svg";
import deleteIcon from "../../image-assets/deleteicon.svg";
import deleteIconGrey from "../../image-assets/deleteicongray.svg";
import favoriteHeartIcon from "../../image-assets/favourite-fill-icon.svg";
import favoriteStringsIcon from "../../image-assets/favourite-strings-icon.svg";
import myStringIcon from "../../image-assets/my-strings-icon.svg";
import rightpurIconGray from "../../image-assets/rightgrayicon.svg";
import rightpurIcon from "../../image-assets/rightpuricon.svg";
import saved from "../../image-assets/saved.svg";
import searchCloseIcon from "../../image-assets/searchclose.svg";
import searchIcon from "../../image-assets/searchicon.svg";
import sharedWithMeIcon from "../../image-assets/shared-with-me-icon.svg";
import standardViewEye from "../../image-assets/standard-view-eye.svg";
import unfavouriteIcon from "../../image-assets/unfavouriteicon.svg";
import uploading from "../../image-assets/uploading.svg";
import { changeAutoEnrichBulb, currentAutoEnrichBulb } from "../../redux/reducers/autoEnrichBulb";
import { currentLocalValue, setChange } from "../../redux/reducers/getLocalData";
import { setData, tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { changeLoader } from "../../redux/reducers/loader";
import { stringData } from "../../redux/reducers/localString";
import { changeSidebar, currentSidebar } from "../../redux/reducers/sidebar";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { setString } from "../../redux/reducers/string";
import { changeStringId, currentStringId } from "../../redux/reducers/stringId";
import { changeTypingStatus } from "../../redux/reducers/typingStatus";
import socket from "../../socket";
import { Boxed } from "../../Styles-Elements/Box";
import { ClearButton, CustomRoundedButton } from "../../Styles-Elements/Buttons";
import * as Colors from "../../Styles-Elements/Colors";
import * as FontStyles from "../../Styles-Elements/FontStyles";
import { InputWrapper, TextSearchfield } from "../../Styles-Elements/Inputs";
import { Body, Heading3SemiBold, LargeBody, Subtitle } from "../../Styles-Elements/Labels";
import AddString from "../../dialogs/AddString";
import CreateFolder from "../../dialogs/CreateFolder";
import DeconstructView from "../../dialogs/DeconstructView";
import DeleteSelectedItems from "../../dialogs/DeleteSelectedItems";
import InactiveScreen from "../../dialogs/InactiveScreen";
import MoveStringToFolder from "../../dialogs/MoveStringToFolder";
import RenameString from "../../dialogs/RenameString";
import DeleteStrings from "./../../dialogs/DeleteStrings";
import { postLastActiveString } from "./postGetLastActiveString";
import RestorePrevious from "./RestorePrevious";
import ShowingFolder from "./ShowingFolder";
import StringCollaborationEdit from "./StringCollaborationEdit";
import Strings from "./Strings";
import { currentTrial } from "../../redux/reducers/getTrial";
import "./KeywordStrings.css";
import librarySearch from "../../GlobalModule/librarySearch";
import { changeOnTranslate, currentOnTranslate } from "../../redux/reducers/onTranslate";
import enrichKeywordStringUpdate from "../../GlobalModule/enrichKeywordStringUpdate";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Boxed padding={"16px"}>
          <Typography>{children}</Typography>
        </Boxed>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#373737",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 16px",
    borderRadius: "4px",
    fontFamily: "Poppins",
  },
}))(Tooltip);

let saveTimer = null;

const KeywordStrings = (props) => {
  const strings = useSelector(stringData);
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const [openMenus, setOpenMenus] = useState({
    keyword: [],
    company: [],
  });
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [openFolder, setOpenFolder] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOpenSingle, setDeleteOpenSingle] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [currentActiveString, setCurrentActiveString] = useState({});
  const [searchString, setSearchString] = useState("");
  const [isCheckEditClicked, setIsCheckEditClicked] = useState(false);
  const [openMoveModalM, setOpenMoveModalM] = useState(false);
  const [allString, setAllString] = useState([]);
  const [addStringOpen, setAddStringOpen] = useState(false);
  const [isShareWithMe, setIsShareWithMe] = useState(false);
  const [savedStatus, setSavedStatus] = useState("saved");
  const [currentFolder, setCurrentFolder] = useState({});
  const [stringDropEnable, setStringDropEnable] = useState(true);
  const [folderDropEnable, setFolderDropEnable] = useState(false);
  const [stringFolderData, setStringFolderData] = useState([]);
  const [stringFolderForSearch, setStringFolderForSearch] = useState(false);
  const [folderDropDownValue, setFolderDropDownValue] = useState([]);
  const [stringFolderType, setStringFolderType] = useState("");
  const [stringAllSelection, setStringAllSelection] = useState([]);
  const [folderAllSelection, setFolderAllSelection] = useState([]);
  const [open9, setOpen9] = useState(false);
  const [typingStatus, setTypingStatus] = useState("release");
  const typeParam = useSelector(currentSidebar);
  const getLocalData = useSelector(currentLocalValue);
  const [ifDataFound, setIfDataFound] = useState(false);
  const [types, setTypes] = useState("changeLoad");
  const [errorBool, setErrorBool] = useState(false);
  // addString,deleteString,moveString,FavoriteString,saveString,renameString,createFolder,changeLoad,dontChange,renameFolder,deleteFolder
  const [canCheckExist, setCanCheckExist] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [deconstructViewError, setDeconstructViewError] = useState(false);
  const [deconstructViewError2, setDeconstructViewError2] = useState(true);
  const [keywordViewType, setKeywordViewType] = useState(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
  const [wordArray, setWordArray] = useState([]);
  const [saveHit, setSaveHit] = useState(false);
  const [saveForRender, setSaveForRender] = useState(false);
  const [getLastString, setGetLastString] = useState({});
  const [handleFavoriteClick, setHandleFavoriteClick] = useState(false);
  const [fCount, setFCount] = useState(0);
  const [TranslateYourStringM, setTranslateYourStringM] = useState(false);
  const stringId = useSelector(currentStringId);
  const [isDeleteString, setIsDeleteString] = useState(false);
  const [deletedStringId, setDeletedStringId] = useState(0);
  const [booTheBoolean, setBooTheBoolean] = useState(true);
  const [autoBuildType, setAutoBuildType] = useState("");
  const [isFinal, setIsFinal] = useState(false);
  const [intelRacoonsSocketData, setIntelRacoonsSocketData] = useState([]);
  const [getIntelRacoonForSingleString, setGetIntelRacoonForSingleString] = useState(null);
  const autoEnrichValue = useSelector(currentAutoEnrichBulb);
  const [allStringId, setAllStringId] = useState([]);
  const [preventAutoApiMultiCalling, setPreventAutoApiMultiCalling] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const trialData = useSelector(currentTrial);
  const [resetStep, setResetStep] = useState(false);

  const currentTranslate = useSelector(currentOnTranslate);

  let currentStringValue = useMemo(() => {
    return currentActiveString;
  }, [currentActiveString]);

  let deconstructError = useMemo(() => {
    return deconstructViewError2;
  }, [deconstructViewError2]);

  useEffect(() => {
    if (currentTranslate) {
      checkSubscription();
      setTimeout(() => {
        dispatch(changeOnTranslate(false));
      }, 1000);
    }
  }, [currentTranslate]);

  const callAutoApi = async () => {
    if (preventAutoApiMultiCalling) {
      let res = await getAutoEnrichData([{ string_id: currentActiveString?.id }]);
      let data = res.data;
      if (data?.ack == 1) {
        let data1 = data.data;
        if (intelRacoonsSocketData.length) {
          let l = [];
          let d = data1[0];
          intelRacoonsSocketData.forEach((obj) => {
            if (obj.string_id == d.string_id) {
              l.push(d);
            } else {
              l.push(obj);
            }
          });
          setIntelRacoonsSocketData(l);
        } else {
          setIntelRacoonsSocketData(data1);
        }
        dispatch(
          changeAutoEnrichBulb({
            ...autoEnrichValue,
            isFoundValue: data1[0]?.isDataAvailable,
          })
        );
        setPreventAutoApiMultiCalling(false);
      }
    }
  };

  useEffect(() => {
    callAutoApi();
  }, [preventAutoApiMultiCalling, currentActiveString]);

  const finalSubmit = useCallback(
    (string) => {
      setStringDropEnable(true);
      setAutoBuildType("");
      if (string) {
        setCurrentActiveString({
          ...currentActiveString,
          string_boolean: string,
        });
        setGetLastString({
          ...getLastString,
          string_boolean: string,
        });
      }
    },
    [currentActiveString, getLastString]
  );

  const forFinalCallback = useCallback((bool) => {
    setIsFinal(bool);
  }, []);

  useEffect(() => {
    let data = intelRacoonsSocketData;
    if (data.length) {
      let find = data.find((a) => a.string_id == currentActiveString?.id);
      if (find !== undefined) {
        dispatch(
          changeAutoEnrichBulb({
            ...autoEnrichValue,
            isFoundValue: find.isDataAvailable,
          })
        );
        setGetIntelRacoonForSingleString(find);
      }
    }
  }, [intelRacoonsSocketData]);

  useEffect(() => {
    if (typeParam == "keyword") {
      if (allStringId.length) {
        let da = {
          stringData: allStringId,
          userId: auth?.user_id,
          type: "multiple",
        };

        setTimeout(() => {
          socket.emit("auto-enrich", da);
        }, 1000);

        setInterval(() => {
          socket.emit("auto-enrich", [
            {
              stringData: [
                {
                  string_id: currentActiveString?.id,
                  userId: auth?.user_id,
                },
              ],
            },
          ]);
        }, 60000);
      }
    }
  }, [allStringId]);

  useEffect(() => {
    if (typeParam == "keyword") {
      socket.on("auto-enrich", (data) => {
        setSocketData(data);
      });
    }
  }, [typeParam, intelRacoonsSocketData]);

  useEffect(() => {
    let data = socketData;
    if (data !== null) {
      if (data.userId == auth?.user_id) {
        if (data.type == "single") {
          if (intelRacoonsSocketData.length) {
            let l = [];
            let d = data.data[0];
            intelRacoonsSocketData.forEach((obj) => {
              if (obj.string_id == d.string_id) {
                l.push(d);
              } else {
                l.push(obj);
              }
            });
            setIntelRacoonsSocketData(l);
          } else {
            setIntelRacoonsSocketData(data.data);
          }
        } else if (data.type == "multiple") {
          setIntelRacoonsSocketData(data.data);
        }
      }
    }
  }, [socketData]);

  useEffect(() => {
    socket.on("string_collab_data", (data) => {
      if (data.length) {
        data.forEach((obj) => {
          let userId = obj?.user_id;
          let stringId = obj?.string_id;
          let deleteUserId = obj?.delete_user_id;

          if (currentActiveString?.id === stringId && auth?.user_id === userId) {
            setIsDeleteString(true);
            setDeletedStringId(stringId);
          }

          if (userId == 0 && currentActiveString?.id === stringId && auth?.user_id !== deleteUserId) {
            setIsDeleteString(true);
            setDeletedStringId(stringId);
          }
        });
      }
    });
  }, [currentActiveString]);

  useEffect(() => {
    //this will use to take intial value of keywordType which used in last session.
    let view = addDeleteGetLocalStorage(STORAGE.VIEW_TYPE, KEYWORD_VIEW_TYPE.STANDARD_VIEW, "get", "single");
    if (view != undefined) {
      setKeywordViewType(view);
    }
  }, []);

  useEffect(() => {
    //this program will keep user active.
    var inactivityTime = function () {
      var time;

      window.addEventListener("mousemove", () => {
        resetTimer();
      });

      window.addEventListener("keydown", () => {
        resetTimer();
      });

      window.addEventListener("load", () => {
        resetTimer();
      });

      const resetTimer = () => {
        clearInterval(time);
        time = setInterval(() => {
          setShowInactive(true);
        }, 180000);
      };
    };

    inactivityTime();
  }, []);

  useEffect(() => {
    //this is for dropdown of string folders according to string type.
    if (canCheckExist) {
      let get = getLocalData?.folder_dropdown;
      if (get) {
        setFolderDropDownValue(get);
      }
    }
  }, [canCheckExist]);

  useEffect(() => {
    if (params?.type) {
      addDeleteGetLocalStorage(STORAGE.NEW_SIDE_BAR, params?.type, "add", "single");
      dispatch(changeSidebar());
    }
  }, [window.location.search]);

  /*
   *Check Subscription
   */
  const checkSubscription = async (type = null) => {
    if (errorBool) {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.Keyword_error_for_string,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else if (typingStatus !== "release") {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.YouWereTooFast,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else if (getLastString?.string_boolean == "" || getLastString?.string_boolean == null) {
      dispatch(
        setSnackbar({
          snackbarMessage: "Your Boolean string cannot be blank",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      setTranslateYourStringM(true);
    }
  };

  useEffect(() => {
    if (saveForRender) {
      setTimeout(() => {
        setSaveForRender(false);
      }, 1000);
    }
  }, [saveForRender]);

  const handleSaveString = async () => {
    try {
      setTypingStatus("release");
      setSaveHit(saveHit ? false : true);

      if (!saveForRender) {
        setSaveForRender(true);
      }

      let text = "";
      if (keywordViewType == KEYWORD_VIEW_TYPE.STANDARD_VIEW) {
        let ids = document.getElementById(TEXT_INPUT);
        text = String(ids.innerText);
      } else if (keywordViewType == KEYWORD_VIEW_TYPE.ADVANCED_VIEW) {
        let id = document.getElementById(ADVANCE_KEYWORD_ID);
        let text1 = String(id.innerText);
        text = replaceNewLine(text1);
      }

      let data = {
        string_boolean: String(text),
        string_id: currentActiveString?.id,
        string_type: typeParam,
        string_name: currentActiveString?.string_name,
        user_id: auth?.user_id,
      };

      let res = await globalRequest("put", REQUEST.UPDATE_STRING, data, {}, true);
      let datas = res.data;

      if (datas.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: datas.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: datas.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
      setSavedStatus("uploading");
    } catch (e) {
      // console.log("e ", e);
    }
  };

  const getAutoEnrichData = async (stringData) => {
    let res = await globalRequest(
      "post",
      AUTO_BUILD.GET_AUTO_ENRICH_DATA,
      {
        stringData: stringData,
        userId: auth?.user_id,
      },
      {},
      true
    );
    return res;
  };

  const openAddStringPopup = () => {
    setAddStringOpen(true);
    setAutoBuildType("");
    dispatch(
      changeAutoEnrichBulb({
        ...autoEnrichValue,
        isClicked: false,
      })
    );
  };

  useEffect(() => {
    try {
      if (Object.keys(currentActiveString).length !== 0) {
        if (auth?.user_id == currentActiveString?.string_detail?.created_by) {
          setIsShareWithMe(true);
        }
        if (keywordViewType == KEYWORD_VIEW_TYPE.ADVANCED_VIEW) {
          wordArrayLogic(currentActiveString?.string_boolean);
        }

        if (stringId) {
          callString(stringId);
        }
        setFCount(currentActiveString?.f_count);

        let str = String(currentActiveString?.string_boolean);
        let c = removeAllSpaces(str);
        let change = replaceSmallAndOrNot(c);
        dispatch(setString(change));

        if (typeParam == "keyword") {
          setTimeout(() => {
            if (currentActiveString?.string_boolean) {
              setAutoBuildType("");
            } else {
              setAutoBuildType("none");
            }
          }, 0);
        } else {
          setAutoBuildType("");
        }
      }
    } catch (e) {}
  }, [currentActiveString]);

  const wordArrayLogic = (stringBool) => {
    if (stringBool) {
      let words = logicToSeperateString(stringBool);
      let word = getAllWordWithOperator(words);
      setWordArray(word);
    } else {
      setWordArray([]);
    }
  };

  //localstroage and state change

  const localStorageAndState = async (data) => {
    let response = await globalRequest(
      "get",
      NEW_API.GET_SINGLE_STRING,
      {},
      {
        params: {
          user_id: auth?.user_id,
          string_id: data?.id,
        },
      },
      true
    );

    let data2 = response.data;

    if (data2.status == 1) {
      let data1 = data2.data;
      setCurrentActiveString(data1);
      setGetLastString(data1);
      insertString(data1);
    }
  };

  const insertString = async (data) => {
    await postLastActiveString(auth?.user_id, data.id, typeParam, data);
  };

  const findAndReplace = () => {
    stringFolderData.forEach((obj) => {
      let string = obj.string;

      let find = string.find((a) => a.id == currentActiveString?.id);
      if (find) {
        localStorageAndState(find);
      }
      obj.folder.forEach((obj1) => {
        let stringData = obj1.string_data;
        let find1 = stringData.find((a) => a.id == currentActiveString?.id);
        if (find1) {
          localStorageAndState(find1);
        }
      });
    });
  };

  const f = async () => {
    if (types != "dontChange") {
      if (stringFolderData.length) {
        if (types == "addString" || types == "deleteString" || types == "deleteFolder") {
          putActiveString(stringFolderData);
        } else if (types == "findAndReplace") {
          findAndReplace();
        } else if (types == "changeLoad") {
          let data = getLastString;

          let isEmpty = Object.keys(data).length == 0;
          if (isEmpty) {
            putActiveString(stringFolderData);
          } else {
            if (data?.string_type == typeParam) {
              setIfDataFound(true);
              setTimeout(() => {
                setCurrentActiveString(data);
              }, 100);
            } else {
              setIfDataFound(false);
            }
          }
        } else if (types == "renameString") {
          if (stringFolderData.length) {
            stringFolderData.forEach((obj) => {
              let string01 = obj.string;

              let find01 = string01.find((a) => a.id == currentActiveString?.id);
              if (find01) {
                localStorageAndState(find01);
              }
              if (obj.folder?.length) {
                obj.folder.forEach((obj1) => {
                  let string02 = obj1.string_data;

                  let find02 = string02.find((a) => a.id == currentActiveString?.id);
                  if (find02) {
                    localStorageAndState(find02);
                  }
                });
              }
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    f();
  }, [stringFolderForSearch]);

  const putActiveString = (data) => {
    if (data.length) {
      setFolderDropEnable(false);
      setStringDropEnable(true);

      const favorite = data[0];
      const myString = data[1];

      const favoriteString = favorite.string.length ? favorite.string[0] : null;
      const string = myString?.string.length ? myString?.string[0] : null;
      const folder = myString?.folder.length ? myString?.folder[0] : null;
      let shareWithMe = null;
      let shareWithMeFolder = null;

      if (auth?.access == "user") {
        shareWithMe = data[2]?.string.length ? data[2]?.string[0] : null;
        shareWithMeFolder = data[2]?.folder.length ? data[2]?.folder[0] : null;
      } else {
        shareWithMe = data[2]?.string.length ? data[2]?.string[0] : null;
        shareWithMeFolder = data[2]?.folder.length ? data[2]?.folder[0] : null;
      }

      if (string) {
        localStorageAndState(string);
        setIfDataFound(true);
      } else if (favoriteString) {
        localStorageAndState(favoriteString);
        setIfDataFound(true);
      } else if (shareWithMe) {
        localStorageAndState(shareWithMe);
        setIfDataFound(true);
      } else if (shareWithMeFolder) {
        if (shareWithMeFolder.string_data.length) {
          localStorageAndState(shareWithMeFolder.string_data[0]);
          setIfDataFound(true);
        }
      } else if (folder) {
        let strings = folder?.string_data[0] ? folder?.string_data[0] : null;
        if (strings) {
          localStorageAndState(strings);
          setIfDataFound(true);
        }
      } else {
        setCurrentActiveString({});
        setIfDataFound(false);
      }
    }
  };

  useEffect(() => {
    socket.on("get_allStringListing_common", (data) => {
      if (auth?.user_id == data?.user_id && typeParam == data.type) {
        let manipuate = manipulateStringFolder(data);
        setStringFolderData(manipuate);
        setStringFolderForSearch(manipuate);
      }
    });
  }, [typeParam]);

  const getAllStringFolder = (type) => {
    let role = auth?.access;
    let userId = auth?.user_id;
    let teamId = auth?.team_id;

    setTypes(type);

    let data = {
      role: role,
      user_id: userId,
      team_id: teamId,
      type: typeParam,
    };
    socket.emit("get_allStringListing_common", data);
  };

  const callString = async (stringId) => {
    let response = await globalRequest(
      "get",
      NEW_API.GET_SINGLE_STRING,
      {},
      {
        params: {
          user_id: auth?.user_id,
          string_id: stringId,
        },
      },
      true
    );
    let data = response.data;

    if (data.status == 1) {
      let data1 = data.data;
      await postLastActiveString(auth?.user_id, data1.id, data1.string_type, data1);
      setGetLastString(data1);
      setCurrentActiveString(data1);
      addDeleteGetLocalStorage(STORAGE.STRING_ID, 0, "add", "single");
      dispatch(changeStringId());
    }
  };

  const getAllString = async () => {
    if (typeParam) {
      dispatch(changeLoader(true));

      let response = await globalRequest(
        "get",
        NEW_API.GET_ALL_STRING,
        {},
        {
          params: {
            user_id: auth?.user_id,
            team_id: auth?.team_id,
            type: typeParam,
            role: auth?.access,
          },
        },
        true
      );
      let data = response.data;
      if (data.status == 1) {
        let data1 = data.data;
        setCurrentActiveString({});

        setTimeout(() => {
          setGetLastString(data1.last_string);

          let manipulate = manipulateStringFolder(data1);
          setStringFolderData(manipulate);
          setStringFolderForSearch(manipulate);

          let stringData = [];
          manipulate.forEach((obj) => {
            if (obj.string.length) {
              obj.string.forEach((obj1) => {
                stringData.push({
                  string_id: obj1.id,
                });
              });
            }

            if (obj.folder.length) {
              obj.folder.forEach((obj2) => {
                if (obj2.string_data.length) {
                  obj2.string_data.forEach((obj4) => {
                    stringData.push({
                      string_id: obj4.id,
                    });
                  });
                }
              });
            }
          });

          setAllStringId(stringData);

          try {
            if (data1[1]?.folder?.length) {
              setCurrentFolder(data1[1]?.folder[0]);
            } else {
              if (data1[2]?.folder.length) {
                setCurrentFolder(data1[2]?.folder[0]);
              }
            }
          } catch (e) {}
        }, 1);

        setCanCheckExist(true);
      }

      dispatch(changeLoader(false));
    }
  };

  const manipulateStringFolder = (data) => {
    let a = [];

    let role = auth?.access;
    let favString = [];

    if (data.favourite_string?.length) {
      data.favourite_string.forEach((obj) => {
        favString.push({
          id: obj.string_id,
          string_name: obj.string_name,
          permission: obj.permission,
        });
      });
    }

    let f = favString.sort((a, b) => {
      let a1 = String(a.string_name).toLowerCase();
      let b1 = String(b.string_name).toLowerCase();
      if (a1 < b1) {
        return -1;
      }
      return 1;
    });

    a.push({
      types: "Favorite Strings",
      img: favoriteStringsIcon,
      forType: "favorite",
      string: f,
      folder: [],
    });

    let myString = [];
    if (data.my_string?.string?.length) {
      myString = data.my_string.string.sort((a, b) => {
        let a1 = String(a.string_name).toLowerCase();
        let b1 = String(b.string_name).toLowerCase();
        if (a1 < b1) {
          return -1;
        }
        return 1;
      });
    }

    let myStringFolder = [];
    if (data?.my_string?.folder.length) {
      let folder = [];

      data?.my_string?.folder?.forEach((obj) => {
        let sort = obj.string_data.sort((a, b) => {
          let a1 = String(a.string_name).toLowerCase();
          let b1 = String(b.string_name).toLowerCase();
          if (a1 < b1) {
            return -1;
          }
          return 1;
        });

        folder.push({
          ...obj,
          string_data: sort,
        });
      });

      let folder1 = folder.sort((a, b) => {
        let a1 = String(a.folder_name).toLowerCase();
        let b1 = String(b.folder_name).toLowerCase();
        if (a1 < b1) {
          return -1;
        }
        return 1;
      });
      myStringFolder = folder1;
    }

    a.push({
      types: "My Strings",
      img: myStringIcon,
      forType: "myString",
      string: myString,
      folder: myStringFolder,
    });

    if (role == "user") {
      let shareString = [];
      let shareFolder = [];
      if (data?.shared_with_me?.string.length) {
        shareString = data?.shared_with_me?.string;
      }
      if (data?.shared_with_me?.folder.length) {
        let f = [];

        data?.shared_with_me?.folder.forEach((obj) => {
          let stringData = obj.string_data.sort((a, b) => {
            let a1 = String(a.string_name).toLowerCase();
            let b1 = String(b.string_name).toLowerCase();
            if (a1 < b1) {
              return -1;
            }
            return 1;
          });

          f.push({
            ...obj,
            string_data: stringData,
          });
        });

        let folder2 = f.sort((a, b) => {
          let a1 = String(a.folder_name).toLowerCase();
          let b1 = String(b.folder_name).toLowerCase();
          if (a1 < b1) {
            return -1;
          }
          return 1;
        });

        shareFolder = folder2;
      }

      a.push({
        types: "Shared With Me",
        img: sharedWithMeIcon,
        forType: "shareWithMe",
        string: shareString,
        folder: shareFolder,
      });
    } else {
      data.all_users?.forEach((obj) => {
        const firstName = obj.first_name;
        const lastName = obj.last_name;
        const emailId = obj.email_id;
        const stringCt = obj.string_ct;

        const allString = obj.all_string.sort((a, b) => {
          if (a.string_name < b.string_name) {
            return -1;
          } else {
            return 1;
          }
        });

        let f = [];

        obj.all_folder?.forEach((obj) => {
          let stringData = obj.string_data.sort((a, b) => {
            if (a.string_name < b.string_name) {
              return -1;
            } else {
              return 1;
            }
          });

          f.push({
            ...obj,
            string_data: stringData,
          });
        });

        let folder2 = f.sort((a, b) => {
          if (a.folder_name < b.folder_name) {
            return -1;
          } else {
            return 1;
          }
        });

        const allFolder = folder2;

        a.push({
          types: firstName == "" || firstName == " " ? emailId : `${firstName} ${lastName}`,
          img: sharedWithMeIcon,
          forType: "shareWithMe-1",
          string: allString,
          folder: allFolder,
          user_info: {
            firstName: firstName,
            lastName: lastName,
            emailId: emailId,
            stringCt: stringCt,
          },
        });
      });
    }
    return a;
  };

  useEffect(() => {
    request();
  }, []);

  const request = async () => {
    let userId = auth?.user_id;
    let url = MANAGE_TEAM.get_single_user;

    const res = await globalRequest(
      "get",
      url,
      {},
      {
        params: {
          user_id: userId,
        },
      },
      true
    );

    if (res.data.status == 1) {
      const access = res.data.data.access;
      if (auth?.access != access) {
        const ds = {
          ...auth,
          access: access,
        };
        addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, ds, "add", "single");
        dispatch(setData());
        window.location.reload();
      }
    }
  };

  const handleAddFavourite = async (addRemove) => {
    if (trialData?.checkPay) {
      const formData = new FormData();
      formData.append("user_id", auth?.user_id);
      formData.append("string_id", currentActiveString?.id);
      formData.append("type", "web"); //browser_type
      let url = REQUEST.MARK_FAVORITE_STRING_REQUEST;
      setHandleFavoriteClick(handleFavoriteClick ? false : true);
      const response = await globalRequest("post", url, formData, {}, true);
      let res = response.data;
      if (res.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
      } else if (res.status == 0) {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      let socketData = {
        user_id: auth?.user_id,
        string_id: currentActiveString?.id,
        type: "web",
      };

      if (addRemove == "remove") {
        setFCount(0);
        socketData.f_count = 0;
      } else {
        setFCount(1);
        socketData.f_count = 1;
      }
      getAllStringFolder("dontChange");
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.dontHaveActivePlan,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (keywordViewType === KEYWORD_VIEW_TYPE.ADVANCED_VIEW) {
      if (getLastString.string_boolean === "" || getLastString.string_boolean === null) {
        setKeywordViewType(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
      }
    }
  }, [getLastString]);

  const handleSearchString = async (e) => {
    let value = String(e.target.value).toLowerCase();

    let menu = {
      company: [],
      keyword: [],
    };

    let a = [];

    stringFolderData.forEach((obj, index) => {
      let folders = obj.folder;
      if (folders) {
        folders.forEach((a) => {
          let find = folderDropDownValue.find((b) => a.id == b.id);
          if (find == undefined) {
            setFolderDropDownValue((folderDropDownValue) => [...folderDropDownValue, { id: a.id, type: typeParam }]);
          }
        });
      }
      a.push(index);
    });

    if (typeParam == "keyword") {
      menu.keyword = a;
    } else {
      menu.company = a;
    }

    setOpenMenus(menu);

    if (value == "") {
      setStringFolderData(stringFolderForSearch);
    } else {
      let newArray = await librarySearch(value, stringFolderForSearch);
      setStringFolderData(newArray);
    }
  };

  const deleteAllModal = () => {
    setDeleteOpen(true);
    if (stringAllSelection.length) {
      let id = [];
      stringAllSelection.forEach((obj) => {
        id.push(obj.id);
      });
      setAllString(id);
    }
  };

  const openMoveModal = () => {
    if (stringAllSelection.length) {
      let id = [];
      stringAllSelection.forEach((obj) => {
        id.push(obj.id);
      });
      setAllString(id);
      setOpenMoveModalM(true);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please select string.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  useEffect(() => {
    setTypes("changeLoad");
    setSearchString("");
    getAllString();
    dispatch(setChange());
    if (typeParam == "company") {
      setAutoBuildType("");
    }
  }, [typeParam]);

  useEffect(() => {
    setCurrentActiveString({});
    setTimeout(() => {
      setCurrentActiveString(getLastString);
      wordArrayLogic(getLastString?.string_boolean);
      return () => {
        setCurrentActiveString({});
      };
    }, 50);
  }, [keywordViewType]);

  useEffect(() => {
    if (savedStatus == "uploading") {
      setTimeout(() => {
        setSavedStatus("saved");
      }, 2000);
    }
  }, [savedStatus]);

  useEffect(() => {
    if (stringFolderType == "favorite") {
      let filter = stringAllSelection.filter((obj) => {
        return obj.forType == "favorite";
      });
      setStringAllSelection(filter);
      setFolderAllSelection([]);
    } else if (stringFolderType == "myString") {
      let filter = stringAllSelection.filter((obj) => {
        return obj.forType == "myString";
      });
      setStringAllSelection(filter);

      let filter1 = folderAllSelection.filter((obj) => {
        return obj.forType == "myString";
      });
      setFolderAllSelection(filter1);
    }
  }, [stringFolderType]);

  useEffect(() => {
    if (canCheckExist) {
      try {
        let menuData = addDeleteGetLocalStorage(STORAGE.FOR_MAIN_ACTIVE_DROPDOWN_KEYWORD, {}, "get");
        if (menuData) {
          setOpenMenus(JSON.parse(menuData));
        }
      } catch (e) {}
      setCanCheckExist(false);
    }
  }, [canCheckExist]);

  /**
   *Check Menu when reload a page
   */
  const checkMenuByIndex = (ind = 0) => {
    let indexAll = [];
    if (typeParam == "company") {
      indexAll = openMenus.company;
    }
    if (typeParam == "keyword") {
      indexAll = openMenus.keyword;
    }
    if (indexAll.indexOf(ind) == -1) {
      return false;
    }
    return true;
  };

  const displayBlockString = (ind, forSearch = false, isShow = false) => {
    try {
      let allId;
      if (typeParam == "keyword") {
        allId = openMenus.keyword;
        if (isShow) {
          if (allId.indexOf(ind) == -1) {
            allId.push(ind);
          }
        } else {
          if (allId.indexOf(ind) == -1) {
            allId.push(ind);
          } else {
            allId.splice(allId.indexOf(ind), 1);
          }
        }
        setOpenMenus({ ...openMenus, keyword: allId });
      }
      if (typeParam == "company") {
        allId = openMenus.company;

        if (isShow) {
          if (allId.indexOf(ind) == -1) {
            allId.push(ind);
          }
        } else {
          if (allId.indexOf(ind) == -1) {
            allId.push(ind);
          } else {
            allId.splice(allId.indexOf(ind), 1);
          }
        }
        setOpenMenus({ ...openMenus, company: allId });
      }
      addDeleteGetLocalStorage(STORAGE.FOR_MAIN_ACTIVE_DROPDOWN_KEYWORD, openMenus, "add", "single");
    } catch (e) {}
  };

  const wsResponse = (bool) => {
    if (bool) {
      setSavedStatus("uploading");
      dispatch(setChange());
    }
  };

  return (
    <React.Fragment>
      <div className="keywordstrings-holder">
        {String(trialData?.status) === "trial" || String(trialData?.status) === "trial_completed" ? (
          <Subtitle
            text={typeParam == "keyword" ? "Project Library" : "Company Library"}
            color={Colors.nightPurple}
            padding={"0 0 24px 0"}
          />
        ) : null}

        <div className={"keywordstrings-grid"}>
          <div className="ks-your-strings-holder" style={{ height: "100%" }}>
            <div className="ks-edit-holder">
              <LargeBody
                text={typeParam == "keyword" ? strings.projectLibrary : strings.companyStrings}
                color={Colors.nightPurple}
              />
              <div className="settings-icon-button">
                {!isCheckEditClicked ? (
                  <Boxed
                    display="flex"
                    onClick={() => {
                      if (trialData?.checkPay) {
                        setIsCheckEditClicked(true);
                      } else {
                        dispatch(
                          setSnackbar({
                            snackbarMessage: strings.dontHaveActivePlan,
                            snackbarOpen: true,
                            snackbarState: "error",
                          })
                        );
                      }
                    }}
                  >
                    <ClearButton
                      theme={FontStyles.body}
                      text={strings.edit}
                      textAlign={"left"}
                      color={Colors.darkPurple}
                      padding={"0 0px 0 8px"}
                    />
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 19.9998H19C19.2652 19.9998 19.5196 20.1052 19.7071 20.2927C19.8946 20.4803 20 20.7346 20 20.9998C20 21.2651 19.8946 21.5194 19.7071 21.7069C19.5196 21.8945 19.2652 21.9998 19 21.9998H5C4.73478 21.9998 4.48043 21.8945 4.29289 21.7069C4.10536 21.5194 4 21.2651 4 20.9998C4 20.7346 4.10536 20.4803 4.29289 20.2927C4.48043 20.1052 4.73478 19.9998 5 19.9998ZM4 14.9998L14 4.99984L17 7.99984L7 17.9998H4V14.9998ZM15 3.99984L17 1.99984L20 4.99984L17.999 7.00084L15 3.99984Z"
                        fill="#4A0FA3"
                      />
                    </svg>
                  </Boxed>
                ) : (
                  <></>
                )}

                <div>
                  {isCheckEditClicked ? (
                    <ClearButton
                      onClick={() => {
                        setIsCheckEditClicked(false);
                        setStringAllSelection([]);
                        setFolderAllSelection([]);
                        setStringFolderType("Cancel");
                      }}
                      theme={FontStyles.body}
                      text={strings.cancel}
                      textAlign={"left"}
                      color={Colors.darkPurple}
                      padding={"0 0px 0 8px"}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="ks-text-search-holder">
              <Boxed display="flex" alignItems="center" gap="12px" margin="0 0 16px 0">
                <CustomRoundedButton
                  text={strings.newProject}
                  borderRadius={"8px"}
                  padding={"5px 10px"}
                  className={"ks-new-green-fill-btn"}
                  whiteSpace={"break-spaces"}
                  onClick={openAddStringPopup}
                />
                <CustomRoundedButton
                  text={strings.newFolder}
                  borderRadius={"8px"}
                  padding={"5px 10px"}
                  className={"ks-new-green-outline-btn"}
                  whiteSpace={"break-spaces"}
                  onClick={() => setOpenFolder(true)}
                />
              </Boxed>
              <InputWrapper>
                <TextSearchfield
                  placeholder="Search for a String"
                  backgroundColor={Colors.whiteGray}
                  onChange={(e) => {
                    handleSearchString(e);
                    setSearchString(e.target.value);
                  }}
                  value={searchString}
                />
                <button className="searchbtn">
                  <img src={searchIcon} className="" alt="" />
                </button>
                {searchString.length > 0 ? (
                  <button
                    className="searchclosebtn"
                    onClick={() => {
                      setSearchString("");
                      handleSearchString({ target: { value: "" } });
                    }}
                  >
                    <img src={searchCloseIcon} className="" alt="" />
                  </button>
                ) : (
                  ""
                )}
              </InputWrapper>
            </div>
            <div className="ks-folder-string-holder ks-fsh-504">
              {stringFolderData.map((obj, index1) => {
                return (
                  <div key={index1} className="ks-fsh-favourite-str-holder">
                    <Boxed
                      onClick={() => {
                        displayBlockString(index1, false);
                      }}
                      onDoubleClick={() => {
                        displayBlockString(index1, false);
                      }}
                      display={"flex"}
                      alignItems={"center"}
                      padding={"0 0 8px 0"}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        id={`addString${index1}`}
                        src={dropArrowIconngreen}
                        alt=""
                        className={checkMenuByIndex(index1) ? "rotate-img" : ""}
                      />
                      <Body
                        text={obj.types}
                        color={Colors.darkPurple}
                        padding={"0px 0px 0px 8px"}
                        className="text-truncate"
                      />
                    </Boxed>
                    <Collapse in={checkMenuByIndex(index1)} timeout="auto" unmountOnExit>
                      <Boxed id={`string${index1}`}>
                        {obj.string.length
                          ? obj.string.map((obj1, index2) => {
                              let isChecked = false;

                              stringAllSelection.forEach((obj) => {
                                if (obj.id == obj1?.id) {
                                  isChecked = true;
                                }
                              });

                              let findData = intelRacoonsSocketData.find((a) => a.string_id == obj1.id);
                              let checkDataAvailable = findData !== undefined ? findData?.isDataAvailable : false;

                              return (
                                <div key={index2}>
                                  <Strings
                                    isAutoRichDataAvailable={checkDataAvailable}
                                    currentActiveString={currentActiveString}
                                    dataObj={obj1}
                                    props={props}
                                    callback={async (data) => {
                                      setOpen9(false);
                                      if (typingStatus == "release") {
                                        if (data?.id == deletedStringId) {
                                          dispatch(
                                            setSnackbar({
                                              snackbarState: "error",
                                              snackbarMessage:
                                                strings.UnfortunatelyThestringownerhasremovedyourcollaborationaccess_text,
                                              snackbarOpen: true,
                                            })
                                          );
                                        } else {
                                          setCurrentActiveString(data);
                                          setGetLastString(data);
                                          setIsDeleteString(false);
                                          setAutoBuildType("");
                                          addDeleteGetLocalStorage(
                                            STORAGE.SELECTION_TYPE,
                                            obj.forType,
                                            "add",
                                            "single"
                                          );
                                          dispatch(
                                            changeAutoEnrichBulb({
                                              isClicked: false,
                                              isFoundValue: false,
                                            })
                                          );
                                          setKeywordViewType(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
                                          if (obj.forType == "shareWithMe" || obj.forType == "shareWithMe-1") {
                                            setIsShareWithMe(false);
                                          } else {
                                            setIsShareWithMe(true);
                                          }
                                          dispatch(
                                            changeAutoEnrichBulb({
                                              ...autoEnrichValue,
                                              isClicked: false,
                                              isFoundValue: false,
                                            })
                                          );
                                          setPreventAutoApiMultiCalling(true);
                                        }
                                      } else {
                                        dispatch(
                                          setSnackbar({
                                            snackbarMessage: strings.YouWereTooFast,
                                            snackbarOpen: true,
                                            snackbarState: "error",
                                          })
                                        );
                                      }
                                    }}
                                    callGetFun={(bool, type) => {
                                      if (bool) {
                                        getAllStringFolder(type);
                                      }
                                    }}
                                    type={obj.forType}
                                    smClicked={
                                      stringDropEnable
                                        ? currentActiveString?.id == obj1?.id
                                          ? "sm-clicked"
                                          : ""
                                        : null
                                    }
                                    handleDropDown={() => {
                                      setFolderDropEnable(false);
                                      setStringDropEnable(true);
                                      setOpen9(false);
                                    }}
                                    userInfoForChangeOwner={obj?.user_info}
                                    isCheckboxChecked={isChecked}
                                    enableCheckbox={
                                      (stringFolderType != obj.forType &&
                                        stringAllSelection.length &&
                                        folderAllSelection.length) ||
                                      (stringFolderType != obj.forType && stringAllSelection.length) ||
                                      (stringFolderType != obj.forType && folderAllSelection.length)
                                        ? false
                                        : true
                                    }
                                    checkboxCallback={(data, e) => {
                                      setStringFolderType(obj.forType);

                                      if (e.target.checked) {
                                        setStringAllSelection((stringAllSelection) => [
                                          ...stringAllSelection,
                                          { ...data, forType: obj.forType },
                                        ]);
                                      } else {
                                        let filter = stringAllSelection.filter((obj) => {
                                          return obj.id != data.id;
                                        });
                                        setStringAllSelection(filter);
                                      }
                                    }}
                                    isEditChecked={isCheckEditClicked}
                                    closeMenu={open9}
                                    folderId={(id) => {
                                      let find = folderDropDownValue.find((a) => a.id == id);
                                      if (find == undefined) {
                                        setFolderDropDownValue((folderDropDownValue) => [
                                          ...folderDropDownValue,
                                          { id: id, type: typeParam },
                                        ]);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            })
                          : null}

                        {obj.folder.length
                          ? obj.folder.map((folderData, index1) => {
                              let isDropDown = false;
                              if (folderDropDownValue.length) {
                                folderDropDownValue.forEach((obj) => {
                                  if (obj.id == folderData.id) {
                                    isDropDown = true;
                                  }
                                });
                              }

                              let isChecked = false;

                              folderAllSelection.forEach((obj) => {
                                if (obj.id == folderData.id) {
                                  isChecked = true;
                                }
                              });

                              return (
                                <div key={index1}>
                                  <ShowingFolder
                                    autoRichData={intelRacoonsSocketData}
                                    dataObj={folderData}
                                    props={props}
                                    callback={(stringData) => {
                                      if (typingStatus == "release") {
                                        if (stringData?.id == deletedStringId) {
                                          dispatch(
                                            setSnackbar({
                                              snackbarState: "error",
                                              snackbarMessage:
                                                strings.UnfortunatelyThestringownerhasremovedyourcollaborationaccess_text,
                                              snackbarOpen: true,
                                            })
                                          );
                                        } else {
                                          setCurrentActiveString(stringData);
                                          setGetLastString(stringData);
                                          setIsDeleteString(false);
                                          addDeleteGetLocalStorage(
                                            STORAGE.SELECTION_TYPE,
                                            obj.forType,
                                            "add",
                                            "single"
                                          );
                                          dispatch(
                                            changeAutoEnrichBulb({
                                              isClicked: false,
                                              isFoundValue: false,
                                            })
                                          );
                                          setKeywordViewType(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
                                          if (obj.forType == "shareWithMe" || obj.forType == "shareWithMe-1") {
                                            setIsShareWithMe(false);
                                          } else {
                                            setIsShareWithMe(true);
                                          }
                                          dispatch(
                                            changeAutoEnrichBulb({
                                              ...autoEnrichValue,
                                              isClicked: false,
                                              isFoundValue: false,
                                            })
                                          );
                                          setPreventAutoApiMultiCalling(true);
                                        }
                                      } else {
                                        dispatch(
                                          setSnackbar({
                                            snackbarMessage: strings.YouWereTooFast,
                                            snackbarOpen: true,
                                            snackbarState: "error",
                                          })
                                        );
                                      }
                                    }}
                                    getFolderDataCallback={(bool, type) => {
                                      if (bool) {
                                        getAllStringFolder(type);
                                        setFolderDropEnable(false);
                                        setStringDropEnable(true);
                                      }
                                    }}
                                    isEditChecked={isCheckEditClicked}
                                    type={obj.forType}
                                    enableCheckbox={
                                      (stringFolderType != obj.forType && folderAllSelection.length) ||
                                      (stringFolderType != obj.forType && stringAllSelection.length) ||
                                      (stringFolderType != obj.forType &&
                                        folderAllSelection.length &&
                                        stringAllSelection.length)
                                        ? false
                                        : true
                                    }
                                    currentActiveString={currentActiveString}
                                    rsbClicked={
                                      folderDropEnable
                                        ? folderData?.id == currentFolder?.id
                                          ? "rsb-clicked"
                                          : null
                                        : null
                                    }
                                    callbackFolderData={(dataObj) => {
                                      setCurrentFolder(dataObj);
                                    }}
                                    handleDropDown={() => {
                                      setFolderDropEnable(true);
                                      setStringDropEnable(false);
                                    }}
                                    dropDownChecked={isDropDown}
                                    checkboxCallback={(data, e) => {
                                      setStringFolderType(obj.forType);
                                      if (e.target.checked) {
                                        setFolderAllSelection((folderAllSelection) => [
                                          ...folderAllSelection,
                                          { ...data, forType: obj.forType },
                                        ]);
                                      } else {
                                        let filter = folderAllSelection.filter((obj) => {
                                          return obj.id != data.id;
                                        });
                                        setFolderAllSelection(filter);
                                      }
                                    }}
                                    isCheckboxChecked={isChecked}
                                    userInfoForChangeOwner={obj?.user_info}
                                    enableCheckboxString={
                                      (stringFolderType != obj.forType &&
                                        stringAllSelection.length &&
                                        folderAllSelection.length) ||
                                      (stringFolderType != obj.forType && stringAllSelection.length) ||
                                      (stringFolderType != obj.forType && folderAllSelection.length)
                                        ? false
                                        : true
                                    }
                                    checkboxCallbackString={(data, e) => {
                                      setStringFolderType(obj.forType);

                                      if (e.target.checked) {
                                        setStringAllSelection((stringAllSelection) => [
                                          ...stringAllSelection,
                                          { ...data, forType: obj.forType },
                                        ]);
                                      } else {
                                        let filter = stringAllSelection.filter((obj) => {
                                          return obj.id != data.id;
                                        });
                                        setStringAllSelection(filter);
                                      }
                                    }}
                                    isEditCheckedString={isCheckEditClicked}
                                    stringAllSelection={stringAllSelection}
                                    typingStatus={typingStatus}
                                    closeMenu={open9}
                                    folderId={(id) => {
                                      let find = folderDropDownValue.find((a) => a.id == id);
                                      if (find == undefined) {
                                        setFolderDropDownValue((folderDropDownValue) => [
                                          ...folderDropDownValue,
                                          { id: id, type: typeParam },
                                        ]);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </Boxed>
                    </Collapse>
                  </div>
                );
              })}
            </div>

            {isCheckEditClicked ? (
              <>
                <div className="bottom-delet-holder">
                  {folderAllSelection.length || stringAllSelection.length ? (
                    <>
                      <Boxed display={"flex"} alignItems={"center"} padding={"0 8px 0 0"} style={{ cursor: "pointer" }}>
                        <img src={deleteIcon} width="16" height="16" alt="" />
                        <Body
                          text={strings.delete}
                          color={Colors.darkPurple}
                          padding={"0px 0px 0px 8px"}
                          onClick={deleteAllModal}
                        />
                      </Boxed>
                    </>
                  ) : (
                    <>
                      <Boxed display={"flex"} alignItems={"center"} padding={"0px"}>
                        <img src={deleteIconGrey} width="16" height="16" alt="" />
                        <Body text={strings.delete} color={Colors.darkGray} padding={"0px 0px 0px 8px"} />
                      </Boxed>
                    </>
                  )}

                  {stringAllSelection.length && folderAllSelection.length ? (
                    <>
                      <Boxed display={"flex"} alignItems={"center"} padding={"0px"}>
                        <img src={rightpurIcon} width="16" height="16" alt="" />
                        <Body text={strings.move} color={Colors.darkGray} padding={"0px 0px 0px 8px"} />
                      </Boxed>
                    </>
                  ) : stringAllSelection.length && folderAllSelection.length == 0 ? (
                    <>
                      <Boxed display={"flex"} alignItems={"center"} padding={"0px"} style={{ cursor: "pointer" }}>
                        <img src={rightpurIcon} width="16" height="16" alt="" />
                        <Body
                          text={strings.move}
                          color={Colors.darkPurple}
                          padding={"0px 0px 0px 8px"}
                          onClick={openMoveModal}
                        />
                      </Boxed>
                    </>
                  ) : (
                    <>
                      <Boxed display={"flex"} alignItems={"center"} padding={"0px"}>
                        <img src={rightpurIconGray} width="16" height="16" alt="" />
                        <Body text={strings.move} color={Colors.darkGray} padding={"0px 0px 0px 8px"} />
                      </Boxed>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
          {ifDataFound ? (
            <>
              <div className="ks-string-detail-holder">
                <div className="ks-string-head-holder grid-item-flex">
                  <div className="text-truncate" style={{ paddingRight: "32px" }}>
                    <Subtitle
                      text={currentActiveString?.string_name ? currentActiveString?.string_name : ""}
                      style={{ Color: "#000" }}
                    />
                  </div>

                  <div className="ks-related-block">
                    {savedStatus === "saved" ? (
                      <LightTooltip
                        onClick={() => {
                          setTypingStatus("typing");
                          clearTimeout(saveTimer);
                          saveTimer = setTimeout(() => {
                            handleSaveString();
                          }, 500);
                        }}
                        title={"Save"}
                        placement="bottom-start"
                      >
                        <a className="ks--related-actv-box">
                          <img src={saved} className="" alt="" />
                        </a>
                      </LightTooltip>
                    ) : (
                      <LightTooltip title={"saving...."} placement="bottom-start">
                        <a className="ks--related-actv-box">
                          <img src={uploading} className="" alt="" />
                        </a>
                      </LightTooltip>
                    )}

                    {fCount == 1 ? (
                      <LightTooltip
                        title={"Remove from favourites"}
                        placement="bottom-start"
                        onClick={() => handleAddFavourite("remove")}
                      >
                        <a className="ks--related-actv-box">
                          <img src={favoriteHeartIcon} className="" alt="" />
                        </a>
                      </LightTooltip>
                    ) : (
                      <LightTooltip
                        title={strings.addToFavourites}
                        placement="bottom-start"
                        onClick={() => handleAddFavourite("add")}
                      >
                        <a className="ks--related-actv-box">
                          <img src={unfavouriteIcon} className="" alt="" />
                        </a>
                      </LightTooltip>
                    )}

                    {autoBuildType == "" ? (
                      <>
                        {getLastString.string_boolean !== null && getLastString.string_boolean !== "" ? (
                          keywordViewType === KEYWORD_VIEW_TYPE.STANDARD_VIEW ? (
                            <LightTooltip
                              title={"Standard View"}
                              placement="bottom-start"
                              onClick={() => {
                                if (typingStatus == "release" && savedStatus == "saved") {
                                  if (errorBool) {
                                    setDeconstructViewError(true);
                                  } else {
                                    setKeywordViewType(KEYWORD_VIEW_TYPE.ADVANCED_VIEW);
                                    addDeleteGetLocalStorage(
                                      STORAGE.VIEW_TYPE,
                                      KEYWORD_VIEW_TYPE.ADVANCED_VIEW,
                                      "add",
                                      "single"
                                    );
                                  }
                                } else {
                                  dispatch(
                                    setSnackbar({
                                      snackbarMessage: strings.YouWereTooFast,
                                      snackbarOpen: true,
                                      snackbarState: "error",
                                    })
                                  );
                                }
                              }}
                            >
                              <a className="ks--related-actv-box">
                                <img src={standardViewEye} className="" alt="" />
                              </a>
                            </LightTooltip>
                          ) : (
                            <LightTooltip
                              title={"Deconstruct View"}
                              placement="bottom-start"
                              onClick={() => {
                                if (typingStatus == "release" && savedStatus == "saved") {
                                  if (errorBool) {
                                    setDeconstructViewError(true);
                                  } else {
                                    setKeywordViewType(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
                                    addDeleteGetLocalStorage(
                                      STORAGE.VIEW_TYPE,
                                      KEYWORD_VIEW_TYPE.STANDARD_VIEW,
                                      "add",
                                      "single"
                                    );
                                  }
                                } else {
                                  dispatch(
                                    setSnackbar({
                                      snackbarMessage: strings.YouWereTooFast,
                                      snackbarOpen: true,
                                      snackbarState: "error",
                                    })
                                  );
                                }
                              }}
                            >
                              <a className="ks--related-actv-box">
                                <img src={deconstructViewEye} className="" alt="" />
                              </a>
                            </LightTooltip>
                          )
                        ) : null}
                      </>
                    ) : (
                      <>
                        {isFinal ? (
                          <LightTooltip
                            title={deconstructViewError2 ? "Decontruct View" : "Standard View"}
                            placement="bottom-start"
                            onClick={() => {
                              if (deconstructViewError2) {
                                setDeconstructViewError2(false);
                              } else {
                                setDeconstructViewError2(true);
                              }
                            }}
                          >
                            <a className="ks--related-actv-box">
                              <img
                                src={deconstructViewError2 ? deconstructViewEye : standardViewEye}
                                className=""
                                alt=""
                              />
                            </a>
                          </LightTooltip>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                {autoEnrichValue?.isClicked ? (
                  <>
                    <div style={{ width: "100%" }}>
                      <AutoRichEditor
                        string={getLastString?.string_boolean}
                        intelRacoonData={getIntelRacoonForSingleString}
                        decontructValue={keywordViewType == KEYWORD_VIEW_TYPE.STANDARD_VIEW ? false : true}
                        wordArray={wordArray}
                        stringId={currentActiveString?.id}
                        stringCallback={(string) => {
                          setCurrentActiveString({
                            ...currentActiveString,
                            string_boolean: string,
                          });
                          setGetLastString({
                            ...getLastString,
                            string_boolean: string,
                          });
                          dispatch(
                            changeAutoEnrichBulb({
                              ...autoEnrichValue,
                              isClicked: false,
                              isFoundValue: false,
                            })
                          );
                          setKeywordViewType(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
                          setTimeout(() => {
                            handleSaveString();
                          }, 100);
                        }}
                        cancelIntel={() => {
                          setCurrentActiveString({
                            ...currentActiveString,
                            string_boolean: getLastString?.string_boolean,
                          });
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {keywordViewType == KEYWORD_VIEW_TYPE.STANDARD_VIEW ? (
                      <React.Fragment>
                        <div>
                          <>
                            {autoBuildType == "" ? (
                              <EditorView
                                currentActiveString={currentActiveString}
                                isShareWithMe={isShareWithMe}
                                wsResponse={wsResponse}
                                revisionCallback={(currentString) => {
                                  let data = currentString;
                                  try {
                                    delete data.revision_id;
                                    delete data.type;
                                    delete data.index;
                                  } catch (e) {}
                                  localStorageAndState(data);
                                  getAllStringFolder("dontChange");
                                }}
                                typingStatus={(status) => {
                                  setTypingStatus(status);
                                  dispatch(changeTypingStatus(status));
                                }}
                                errorBool={(bool) => {
                                  setErrorBool(bool);
                                }}
                                saveHit={saveHit}
                                currentStringCallback={(string) => {
                                  if (string == "") {
                                    let ls = {
                                      ...currentActiveString,
                                      string_boolean: string,
                                    };
                                    setCurrentActiveString(ls);
                                    if (typeParam == "keyword") {
                                      setAutoBuildType("none");
                                    }
                                  }
                                  dispatch(setString(string));
                                  let l = {
                                    ...getLastString,
                                    string_boolean: string,
                                  };
                                  setGetLastString(l);
                                  getAllStringFolder("dontChange");
                                }}
                                isDeletedString={isDeleteString}
                                booTheBoolean={booTheBoolean}
                              />
                            ) : (
                              <AutoBuildHome
                                finalSubmit={finalSubmit}
                                currentActiveString={currentStringValue}
                                forFinalCallback={forFinalCallback}
                                decontructValue={deconstructError}
                                resetStep={resetStep}
                              />
                            )}
                          </>
                        </div>
                      </React.Fragment>
                    ) : (
                      <>
                        <React.Fragment>
                          {typeParam == "keyword" ? (
                            <>
                              <AdvanceKeyword
                                currentActiveString={currentActiveString}
                                typingStatus={(status) => {
                                  setTypingStatus(status);
                                  dispatch(changeTypingStatus(status));
                                }}
                                wordArray={wordArray}
                                wsResponse={wsResponse}
                                revisionCallback={(currentString) => {
                                  let data = currentString;
                                  try {
                                    delete data.revision_id;
                                    delete data.type;
                                    delete data.index;
                                  } catch (e) {}
                                  localStorageAndState(data);
                                  getAllStringFolder("dontChange");
                                }}
                                currentStringCallback={() => {
                                  setTimeout(() => {
                                    try {
                                      let text = String(document.getElementById(ADVANCE_KEYWORD_ID).innerText);
                                      let string = replaceNewLine(text);
                                      let string1 = removeAllSpaces(string);

                                      if (string1 == "") {
                                        let ls = {
                                          ...currentActiveString,
                                          string_boolean: string1,
                                        };
                                        setCurrentActiveString(ls);
                                      }

                                      dispatch(setString(string1));
                                      let l = {
                                        ...getLastString,
                                        string_boolean: string1,
                                      };
                                      setGetLastString(l);

                                      getAllStringFolder("dontChange");
                                    } catch (e) {}
                                  }, 1000);
                                }}
                                reloadWordArray={(string) => {
                                  wordArrayLogic(string);
                                }}
                                isDeletedString={isDeleteString}
                                booTheBoolean={booTheBoolean}
                                errorBool={(bool) => {
                                  setErrorBool(bool);
                                }}
                                saveHit={saveHit}
                              />
                            </>
                          ) : (
                            <AdvanceEditorCompany
                              currentActiveString={currentActiveString}
                              wsResponse={wsResponse}
                              currentStringCallback={(string) => {
                                if (string == "") {
                                  let ls = {
                                    ...currentActiveString,
                                    string_boolean: string,
                                  };
                                  setCurrentActiveString(ls);
                                }
                                dispatch(setString(string));
                                let l = {
                                  ...getLastString,
                                  string_boolean: string,
                                };
                                setGetLastString(l);
                                getAllStringFolder("dontChange");
                              }}
                              isShareWithMe={isShareWithMe}
                              isDeletedString={isDeleteString}
                            />
                          )}
                        </React.Fragment>
                      </>
                    )}
                  </>
                )}

                <Boxed display={"flex"} alignItems={"flex-start"} style={{ gap: "20px" }}>
                  <RestorePrevious
                    stringId={currentActiveString?.id}
                    dataCallback={(data, index) => {
                      setAutoBuildType("");
                      setCurrentActiveString({
                        collaborate_toggle: data.collaborate_toggle,
                        f_count: data.f_count,
                        id: data.f_count,
                        string_boolean: data?.string_boolean,
                        string_detail: data.string_detail,
                        id: data.string_id,
                        string_name: data.string_name,
                        string_type: data.string_type,
                        type: "restore",
                        index: index,
                        revision_id: data.id,
                      });
                    }}
                    permission={
                      auth?.access == "owner" ||
                      auth?.access == "admin" ||
                      currentActiveString?.string_detail?.created_by == auth?.user_id
                        ? true
                        : false
                    }
                  />

                  {isShareWithMe ? (
                    <StringCollaborationEdit
                      dataObj={currentActiveString}
                      callback={(bool) => {
                        if (bool) {
                          getAllStringFolder("dontChange");
                        }
                      }}
                    />
                  ) : null}

                  {!isShareWithMe ? (
                    auth?.access == "owner" || auth?.access == "admin" ? (
                      <StringCollaborationEdit
                        dataObj={currentActiveString}
                        callback={(bool) => {
                          if (bool) {
                            getAllStringFolder("findAndChange");
                          }
                        }}
                      />
                    ) : null
                  ) : null}
                </Boxed>
              </div>
            </>
          ) : (
            <>
              <div className="add-new-sqd-team-holder">
                <img src={booAddSqaud1Img} height={300} alt="" />
                <Heading3SemiBold
                  text={`it's time to create your first ${typeParam === "keyword" ? "project" : "company"} string!`}
                  color={Colors.nightGray}
                  margin={"24px 0 0 0"}
                  textAlign={"center"}
                />
                <Body
                  text={`Your strings will appear here after you create your first string.`}
                  color={Colors.darkGray}
                  margin={"16px 0 0 0"}
                  textAlign={"center"}
                />
              </div>
            </>
          )}
        </div>

        {openFolder ? (
          <CreateFolder
            closePopup={() => {
              setOpenFolder(false);
            }}
            callback={(bool) => {
              if (bool) {
                getAllStringFolder("createFolder");
              }
            }}
          />
        ) : null}

        {openMoveModalM ? (
          <MoveStringToFolder
            closePopup={() => setOpenMoveModalM(false)}
            stringId={allString} // this should be array if we want to move multiple string
            type={"multiple"}
            callback={(bool) => {
              if (bool) {
                // getAllStringFolder()
                getAllStringFolder("moveString");
                setIsCheckEditClicked(false);
                setOpenMoveModalM(false);
                setFolderAllSelection([]);
                setStringAllSelection([]);
                setAllString([]);
              }
            }}
          />
        ) : null}

        {deleteOpen ? (
          <DeleteSelectedItems
            closePopup={() => setDeleteOpen(false)}
            allFolder={folderAllSelection}
            allString={allString}
            callback={(bool) => {
              if (bool) {
                getAllStringFolder("deleteString");
                setFolderAllSelection([]);
                setIsCheckEditClicked(false);
                setFolderAllSelection([]);
                setStringAllSelection([]);
                setAllString([]);
                setDeleteOpen(false);
                setDeletedStringId(0);
                setIsDeleteString(false);
              }
            }}
          />
        ) : null}

        {addStringOpen ? (
          <AddString
            closePopup={() => {
              setAddStringOpen(false);
            }}
            popupIsOpen={true}
            callback={(bool, responseData) => {
              if (bool) {
                setAddStringOpen(false);
                setDeletedStringId(0);
                setIsDeleteString(false);

                let data = responseData.data;

                if (typeParam == "keyword") {
                  setAutoBuildType(data?.auto_build_type);
                } else {
                  setAutoBuildType("");
                }

                if (data.folder_id != 0) {
                  getAllStringFolder("dontChange");

                  let find = folderDropDownValue.find((a) => a.id == data.folder_id);
                  if (find?.id != data.folder_id) {
                    setFolderDropDownValue((folderDropDownValue) => [
                      ...folderDropDownValue,
                      { id: data.folder_id, type: typeParam },
                    ]);
                  }

                  setTimeout(() => {
                    try {
                      localStorageAndState(data);
                    } catch (e) {}
                  }, 500);
                } else {
                  getAllStringFolder("dontChange");
                  setTimeout(() => {
                    try {
                      localStorageAndState(data);
                    } catch (e) {}
                  }, 500);
                }

                setIfDataFound(true);

                setTimeout(() => {
                  setKeywordViewType(KEYWORD_VIEW_TYPE.STANDARD_VIEW);
                  addDeleteGetLocalStorage(STORAGE.VIEW_TYPE, KEYWORD_VIEW_TYPE.STANDARD_VIEW, "add", "single");
                  displayBlockString(1, false, true);
                }, 1000);

                let intelRac = {
                  string_id: data?.id,
                  autoRichData: [],
                  isDataAvailable: false,
                };
                setIntelRacoonsSocketData((intelRacoonsSocketData) => [...intelRacoonsSocketData, intelRac]);
              }
            }}
          />
        ) : null}

        {renameOpen ? (
          <RenameString
            closePopup={() => setRenameOpen(false)}
            stringName={currentActiveString?.string_name}
            stringId={currentActiveString?.id}
            callback={(bool) => {
              if (bool) {
                getAllStringFolder("renameString");
                setDeletedStringId(0);
                setIsDeleteString(false);
              }
            }}
          />
        ) : null}

        {deleteOpenSingle ? (
          <DeleteStrings
            closePopup={() => setDeleteOpenSingle(false)}
            stringId={currentActiveString?.id}
            stringName={currentActiveString?.string_name}
            callback={(bool, stringId) => {
              if (bool) {
                getAllStringFolder("deleteString");
                setDeletedStringId(0);
                setIsDeleteString(false);
                let filter = intelRacoonsSocketData.filter((a) => a.string_id != stringId);
                setIntelRacoonsSocketData(filter);
                // getAllStringFolder()
              }
            }}
          />
        ) : null}

        {showInactive ? (
          <InactiveScreen
            callback={() => {
              setShowInactive(false);
              socket.connect();
            }}
          />
        ) : null}

        {deconstructViewError ? (
          <DeconstructView closePopup={() => setDeconstructViewError(false)} currentKeyView={keywordViewType} />
        ) : null}

        {TranslateYourStringM ? (
          <TranslateYourString
            closePopup={() => setTranslateYourStringM(false)}
            setTranslateYourString={() => {
              setTranslateYourStringM(false);
            }}
            getLastString={getLastString}
            creditDetails={0}
            checkSubscription={checkSubscription}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default memo(KeywordStrings);
