import { useState } from "react";
import booSurprised from "./image-assets/boo-surprised.png";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const ProgressBar = ({ loading = false, progressPercent = 0, isAuto = true }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isAuto) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-circle-box">
          <CircularProgress
            variant="determinate"
            value={isAuto ? progress : progressPercent}
            style={{
              width: "200px",
              height: "200px",
              position: "absolute",
              inset: "0",
              margin: "auto",
              zIndex: "999",
              color: "#00E4A2",
            }}
          />
          <div className="loader-circle-white">
            <img src={booSurprised} width="162" alt="" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProgressBar;
