import copy from "copy-to-clipboard";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdvanceViewCopyEditor from "../advance-editor/keyword/AdvanceViewCopyEditor";
import CopySection from "../GlobalModule/CopySection";
import globalRequest from "../GlobalModule/globalRequest";
import removeSingleQuotation from "../GlobalModule/removeSingleQuotation";
import replaceComma from "../GlobalModule/replaceComma";
import updateLastInteraction from "../GlobalModule/updateLastInteraction";
import { KEYWORD_STRING, MISSING_OPERATOR_ERROR, ON_TRIAL_STATUS, TEXT_INPUT } from "../Helpers/Enums";
import userTypingImg from "../image-assets/boo-happy.png";
import booNegative from "../image-assets/boo-negative.png";
import eyeIcon from "../image-assets/Eye.svg";
import booOn from "../image-assets/on.png";
import strings from "../localisation_en.json";
import { getLastActiveString, postLastActiveString } from "../pages/keyword-strings/postGetLastActiveString";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { currentSidebar } from "../redux/reducers/sidebar";
import { setSnackbar } from "../redux/reducers/snackbar";
import socket from "../socket";
import { Boxed } from "../Styles-Elements/Box";
import * as Colors from "../Styles-Elements/Colors";
import { Body } from "../Styles-Elements/Labels";
import addAutoQuotes from "../worker/addAutoQuotes";
import colorStringWorker from "../worker/colorStringWorker";
import colorWorker from "../worker/colorWorker";
import ErrorAll from "../worker/ErrorAll";
import WorkerBuilder from "../worker/WorkerBuilder";
import addQuotes from "./addQuotes";
import AllCharacterAndSymbols from "./AllCharacterAndSymbols";
import colorTheString__ from "./colorTheString__";
import createSpan from "./createSpan";
import cursorPosition from "./cursorPosition";
import findSelectionIndexes from "./findSelectionIndex";
import removeAllSpaces from "./removeAllSpaces";
import removeColor from "./removeColor";
import replaceNewLine from "./replaceNewLine";
import replaceSmallAndOrNot from "./replaceSmallAndOrNot";
import showError from "./showError";
import "../Styles-Elements/AdvancedEditor/AdvancedEditor.css";
import "./keyword.css";
import removeApartFromAndOrNot from "../worker/removeApartFromAndOrNot";
import { currentTrial } from "../redux/reducers/getTrial";
import TypingView from "../GlobalModule/TypingView";

const colorWorkers = new WorkerBuilder(colorWorker);
const autoQuotesWorker = new WorkerBuilder(addAutoQuotes);
const errorWorker = new WorkerBuilder(ErrorAll);
const colorStringWork = new WorkerBuilder(colorStringWorker);
const colorAndOrNotWorker = new WorkerBuilder(removeApartFromAndOrNot);

let saveTimer = null;
let postMessageTimer = null;
let postMessageTimer2 = null;
let quoteTimer = null;
let colorTimer = null;

let andOrNotColorTimer = null;
const randomId = parseInt(Math.random() * 9999 + 100);

const EditorView = ({
  currentActiveString,
  typingStatus,
  wsResponse,
  revisionCallback,
  currentStringCallback,
  saveHit,
  isDeletedString,
  errorBool,
  isShareWithMe = true,
}) => {
  const id = TEXT_INPUT;

  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const [socketData, setSocketData] = useState({});
  const [collaborationStatus, setCollaborationStatus] = useState(false);
  const [permission, setPermission] = useState(0);
  const [isContentEnable, setIsContentEnable] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [quotesData, setQuotesData] = useState([]);
  const ref = useRef();
  const [stringChange, setStringChange] = useState("");
  const typeParam = useSelector(currentSidebar);
  const [textCount, setTextCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [includeQuotation, setIncludeQuotation] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [combos, setCombos] = useState(false);
  const [selectedWord, setSelectedWord] = useState([]);
  const [typing, setTyping] = useState("");
  const trialData = useSelector(currentTrial);

  const getId = () => {
    try {
      return ref.current;
    } catch (e) {
      return {};
    }
  };

  const colorQuotes = (str) => {
    let children = getId().children;
    let regex = /["]/g;
    let match;
    while ((match = regex.exec(str)) !== null) {
      try {
        let ind = match.index;
        let color = children[ind].style.color;
        if (color != "rgb(243, 134, 45)") {
          children[ind].style.color = "#f3862d";
        }
      } catch (e) {}
    }
  };

  useEffect(() => {
    if (quotesData.length) {
      let data = quotesData;

      if (data.length) {
        let indexs = data;
        if (indexs.length) {
          let a = [];
          let bools = false;
          indexs.forEach((objs) => {
            let ind = objs.index;
            let fors = objs.fors;
            let bool = objs.bool;

            if (fors == "quotes") {
              addNodesInBetween(ind, '"', bool);
              a.push(ind);
              bools = true;
            }
          });

          if (bools) {
            let f = a[0] ? a[0] + 1 : 0;
            let l = a[1] ? a[1] : 2;

            if (f == l) {
              setCursorPosition(cursorPosition() + 1);
            } else {
              setCursorPosition(cursorPosition() + 2);
            }
          }
        } else {
          setCursorPosition(cursorPosition() + 1);
        }
        applyFeature();
      }
    }
  }, [quotesData]);

  const rearrangeHtml = () => {
    let pos = cursorPosition();
    var text = String(getId().innerText);
    let removeSpace = removeAllSpaces(text);
    if (removeSpace != "") {
      updateLastInteraction(auth?.user_id);
      let decodeHtml = "";
      if (removeSpace != undefined) {
        decodeHtml = createSpan(removeSpace);
      }
      getId().innerHTML = "";
      getId().innerHTML = decodeHtml;
      applyFeature();
      try {
        colorStringWork.postMessage({
          string: String(removeSpace),
        });
      } catch (e) {}
      try {
        colorWorkers.postMessage({
          string: String(removeSpace),
        });
      } catch (e) {}
      setTextCount(String(removeSpace).trim().length);

      putQuotesOnLast();
      saved("release");
      setTimeout(() => {
        setCursorPosition(pos);
      }, 40);
    }
    saveTimer = null;
  };
  useEffect(() => {
    rearrangeHtml();
  }, [saveHit]);

  useEffect(() => {
    if (errorMsg != MISSING_OPERATOR_ERROR.QUOTATION) {
      autoQuotesWorker.onmessage = (event) => {
        let { data } = event;
        if (data.length) {
          setQuotesData(data);
        }
      };
    }
  }, [errorMsg, currentActiveString]);

  useEffect(() => {
    colorWorkers.onmessage = (event) => {
      let { findAllAndOrNotInsideQuotation } = event.data;
      let { and, or, not } = findAllAndOrNotInsideQuotation;

      try {
        let parent = getId();

        let children = parent.children;

        const color = (index, outColor) => {
          try {
            children[index].innerText = children[index].innerText.toLowerCase();
            let col = children[index].style.color;
            if (col == outColor) {
              children[index].style.color = "";
            }
          } catch (e) {}
        };

        if (and.length) {
          let i = 0;
          const func = (is) => {
            if (and.length != is) {
              let ind = and[is];
              setTimeout(() => {
                let first = children[ind] ? children[ind].innerText : undefined;
                let second = children[ind + 1] ? children[ind + 1].innerText : undefined;
                let third = children[ind + 2] ? children[ind + 2].innerText : undefined;

                if (first == "A") {
                  color(ind, "rgb(37, 146, 224)");
                }
                if (second == "N") {
                  color(ind + 1, "rgb(37, 146, 224)");
                }
                if (third == "D") {
                  color(ind + 2, "rgb(37, 146, 224)");
                }
                func(i);
                i++;
              }, 10);
            }
          };
          func(i);
        }

        if (not.length) {
          let k = 0;
          const func1 = (is) => {
            if (not.length != is) {
              let ind = not[is];
              setTimeout(() => {
                let first = children[ind] ? children[ind].innerText : undefined;
                let second = children[ind + 1] ? children[ind + 1].innerText : undefined;
                let third = children[ind + 2] ? children[ind + 2].innerText : undefined;

                if (first == "N") {
                  color(ind, "rgb(230, 108, 52)");
                }
                if (second == "O") {
                  color(ind + 1, "rgb(230, 108, 52)");
                }
                if (third == "T") {
                  color(ind + 2, "rgb(230, 108, 52)");
                }
                func1(k);
                k++;
              }, 10);
            }
          };
          func1(k);
        }

        if (or.length) {
          let s = 0;
          const func2 = (is) => {
            if (or.length != is) {
              let ind = or[is];
              setTimeout(() => {
                let first = children[ind] ? children[ind].innerText : undefined;
                let second = children[ind + 1] ? children[ind + 1].innerText : undefined;

                if (first == "O") {
                  color(ind, "rgb(34, 180, 122)");
                }
                if (second == "R") {
                  color(ind + 1, "rgb(34, 180, 122)");
                }
                func2(s);
                s++;
              }, 10);
            }
          };
          func2(s);
        }
      } catch (e) {}
    };

    colorStringWork.onmessage = (event) => {
      let { and, or, not, string } = event.data;
      try {
        let parent = getId();
        let currentString = String(parent.innerText);
        if (string == currentString) {
          colorTheString__(and, not, or, parent);
        }
      } catch (e) {
        // console.log("e ", e);
      }
    };

    colorStringWork.onmessage = (event) => {
      try {
        let { and, or, not, string } = event.data;
        let parent = getId();
        let currentString = String(parent.innerText);

        if (string == currentString) {
          colorTheString__(and, not, or, parent);
        } else {
          colorStringWork.postMessage({
            string: currentString,
          });
        }
      } catch (e) {}
    };

    errorWorker.onmessage = (event) => {
      let { data } = event;

      try {
        let parent = getId();
        let string = String(parent.innerText);
        let isErrorCallback = (bool) => {
          if (string) {
            errorBool(bool);
          } else {
            errorBool(false);
          }
        };

        const errMsgCallback = (msg) => {
          if (string) {
            setErrorMsg(msg);
          }
          window.$(`span`).removeClass("error underline missingQuotes right-error");
        };

        showError(parent, data, isErrorCallback, errMsgCallback);
      } catch (e) {}
    };

    colorAndOrNotWorker.onmessage = (event) => {
      let { removeApartFromAndOrNot } = event.data;
      try {
        let parent = getId();
        let children = parent.children;
        let currentString = String(parent.innerText);
        let strArr = [...currentString];
        if (removeApartFromAndOrNot.length) {
          let f = 0;
          let fs = (is) => {
            if (removeApartFromAndOrNot.length != is) {
              let obj = removeApartFromAndOrNot[is];
              setTimeout(() => {
                let start = obj.start;
                let end = obj.end;
                for (let i = start; i < end; i++) {
                  if (strArr[i] !== '"') {
                    try {
                      if (children[i].style.color !== "") {
                        children[i].style.color = "";
                      }
                      if (children[i].innerText !== children[i].innerText.toLowerCase()) {
                        children[i].innerText = children[i].innerText.toLowerCase();
                      }
                    } catch (e) {}
                  }
                }

                fs(f);
                f++;
              }, 0);
            }
          };
          fs(f);
          // setCursorPosition(pos);
        }
      } catch (e) {}
    };
  }, [currentActiveString]);

  useEffect(() => {
    clearTimeout(andOrNotColorTimer);
    andOrNotColorTimer = setTimeout(() => {
      let string = "";
      try {
        string = String(getId().innerText);
      } catch (e) {}

      if (errorMsg != MISSING_OPERATOR_ERROR.DUPLICATE) {
        clearTimeout(postMessageTimer);
        postMessageTimer = setTimeout(() => {
          try {
            colorWorkers.postMessage({
              string: string,
            });
          } catch (e) {}
        }, 120);
      }

      clearTimeout(postMessageTimer2);
      postMessageTimer2 = setTimeout(() => {
        try {
          colorAndOrNotWorker.postMessage({
            string: string,
          });
        } catch (e) {}
      }, 120);

      colorStringWork.postMessage({
        string: string,
      });
    }, 150);
  }, [errorMsg, stringChange]);

  const getHtml = (str) => {
    let parent = getId();
    if (str) {
      let changeStr = replaceSmallAndOrNot(str);
      let decodeHtml = createSpan(changeStr);
      parent.innerHTML = "";
      parent.innerHTML = decodeHtml;

      setTimeout(() => {
        colorStringWork.postMessage({
          string: changeStr,
        });
      }, 300);

      setTimeout(() => {
        applyFeature();
      }, 500);
    } else {
      parent.innerHTML = "";
    }
  };

  const callingGetToggle = async (createdBy, stringId) => {
    let params = {
      user_id: createdBy,
      string_id: stringId,
    };

    let res = await globalRequest(
      "get",
      KEYWORD_STRING.STRING_TOGGLE_GET,
      {},
      {
        params: params,
      },
      true
    );

    if (res?.data?.status == 1) {
      let data = res?.data?.data;
      setCollaborationStatus(data.collaborate_toggle == 1 ? true : false);
    }
  };

  useEffect(() => {
    try {
      let obj = Object.keys(currentActiveString).length !== 0;
      if (obj) {
        setErrorMsg("");

        if (currentActiveString?.type == "restore" && currentActiveString?.index != 0) {
          setIsContentEnable(false);
        } else {
          setIsContentEnable(true);
        }

        callingGetToggle(auth?.user_id, currentActiveString?.id);
        setPermission(parseInt(currentActiveString?.string_detail?.permission));

        setTextCount(
          String(currentActiveString?.string_boolean ? currentActiveString?.string_boolean : "").trim().length
        );

        let stringBoolean = removeAllSpaces(currentActiveString?.string_boolean);
        let changeStr = replaceSmallAndOrNot(stringBoolean);
        let removeCo = replaceComma(changeStr);

        // console.log("remove ",removeCo);
        getHtml(removeCo);
      }
    } catch (e) {}
  }, [currentActiveString]);

  useEffect(() => {
    if (auth?.access == "user") {
      if (currentActiveString?.type == "restore" && currentActiveString?.index != 0) {
        setIsContentEnable(false);
      } else {
        if (auth.user_id == currentActiveString?.string_detail?.created_by) {
          setIsContentEnable(true);
        } else {
          setIsContentEnable(collaborationStatus);
        }
      }
    } else if (auth?.access == "admin" || auth?.access == "owner") {
      if (currentActiveString?.type == "restore" && currentActiveString?.index != 0) {
        setIsContentEnable(false);
      } else {
        setIsContentEnable(true);
      }
    }
  }, [collaborationStatus, currentActiveString]);

  const updateStringInStorage = async (textInput) => {
    let i = await getLastActiveString(auth?.user_id, "keyword");
    let isEmpty = Object.keys(i).length == 0;
    if (!isEmpty) {
      try {
        let obj = {
          ...i,
          string_boolean: textInput,
        };
        await postLastActiveString(auth?.user_id, currentActiveString?.id, "keyword", obj);
      } catch (e) {
        console.log(" e ", e);
      }
    }
  };

  const saved = (status) => {
    typingStatus(status);
    setTyping(status);
    let data = {
      string_id: currentActiveString?.id,
      user_id: currentActiveString?.string_detail?.created_by,
      typing_user_id: auth?.user_id,
      status: status,
      name: auth?.firstname + " " + auth?.lastname,
      browser_type: "web",
      string_type: typeParam,
      random_id: randomId,
    };

    if (status == "release") {
      try {
        let text = String(getId().innerText);
        let string = replaceNewLine(text);
        data.string_boolean = string;
        updateStringInStorage(string);
        currentStringCallback(string);
        socket.emit("poststring", data);

        if (typeParam == "keyword") {
          socket.emit("auto-enrich", {
            stringData: [{ string_id: currentActiveString?.id }],
            userId: auth?.user_id,
            type: "single",
          });
        }

        wsResponse(true);
      } catch (e) {
        // console.log("e ", e);
      }
    } else if (status == "typing") {
      socket.emit("poststring", data);
    }
  };

  const htmlRender = (data) => {
    try {
      let str = String(data.string_boolean);
      let c = removeAllSpaces(str);
      let change = replaceSmallAndOrNot(c);
      setTextCount(String(change).trim().length - 1);
      currentStringCallback(change);
      getHtml(change);
    } catch (e) {}
  };

  useEffect(() => {
    let data = socketData;
    if (data?.string_type == typeParam) {
      if (
        data.browser_type == "chrome" &&
        data.typing_user_id1 == auth?.user_id &&
        data.string_id == currentActiveString?.id &&
        data.status == ""
      ) {
        htmlRender(data);
      }

      if (
        data.browser_type == "web" &&
        data.status == "" &&
        data.string_id == currentActiveString?.id &&
        data.typing_user_id1 == auth?.user_id &&
        data.random_id !== randomId
      ) {
        htmlRender(data);
      }

      if (data.status == "" && data.string_id == currentActiveString?.id && data.typing_user_id1 != auth?.user_id) {
        htmlRender(data);
      }
    }
  }, [socketData]);

  useEffect(() => {
    let handleF = (data) => {
      setSocketData(data);
    };
    socket.on("poststring", handleF);
    return () => {
      socket.off("poststring", handleF);
    };
  }, [typeParam]);

  const copyTextToClip = () => {
    var text = String(getId().innerText);
    let text1 = replaceNewLine(text);
    if (!combos) {
      rearrangeHtml();
    }
    updateLastInteraction(auth?.user_id);

    let finalText = "";

    if (selectedWord.length) {
      let st = "";
      selectedWord.forEach((obj, ind) => {
        if (ind == 0) {
          let str = String(obj?.string).substring(0, 3);
          if (str == "AND" || str == "NOT") {
            let s = String(obj?.string);
            st += `${s.substring(4, s.length)} `;
          } else {
            st += `${obj?.string} `;
          }
        } else {
          st += `${obj?.string} `;
        }
      });
      finalText = st;
    } else {
      finalText = text1;
    }

    if (!includeQuotation) {
      let te = removeSingleQuotation(finalText);
      finalText = te;
    }

    copy(finalText);

    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: "String successfully copied",
        snackbarState: "success",
      })
    );
  };

  const copySelected = () => {
    try {
      let text = window.getSelection();
      let f = text.getRangeAt(0).toString();
      let removeSpace = removeAllSpaces(f);
      updateLastInteraction(auth?.user_id);

      if (text == "") {
        copyTextToClip();
      } else {
        copy(removeSpace);
        window.getSelection().removeAllRanges();
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: "String successfully copied",
            snackbarState: "success",
          })
        );
      }
    } catch (e) {}
  };

  const cutSelected = () => {
    let text = window.getSelection().getRangeAt(0).toString();
    copy(text);
    removeSelected();
    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: "String successfully copied",
        snackbarState: "success",
      })
    );
  };

  const selectTextToClip = () => {
    window.getSelection().selectAllChildren(getId());
    document.execCommand("selectAll");
  };

  const setCursorPosition = (index) => {
    let ind = index;
    const string = String(getId().innerText);

    let regex = /(\(""\))/g;
    let match;
    while ((match = regex.exec(string)) !== null) {
      ind = match.index + 2;
      break;
    }

    try {
      let parent = getId();
      let range = document.createRange();
      let sel = document.getSelection();
      range.setStart(parent, ind);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      parent.focus();
    } catch (e) {
      try {
        setCursorPosition(string.length);
      } catch (e) {}
    }
  };

  const applyFeature = () => {
    try {
      let parent = getId();
      let string = String(parent.innerText);
      colorQuotes(string);
      errorWorker.postMessage({
        string: string,
      });
    } catch (e) {
      console.log("error ", e);
    }
  };

  const createNode = (ch, isAutoQuotes = true) => {
    try {
      let pos = cursorPosition();
      let parent = getId();
      const node1 = document.createElement("span");

      if (ch === " ") {
        node1.innerHTML = "&nbsp;";
      } else {
        node1.innerText = ch;
      }

      if (parent.children.length === 0) {
        parent.appendChild(node1);
      } else {
        if (parent.children[pos - 1]) {
          parent.children[pos - 1].insertAdjacentElement("afterEnd", node1);
        } else {
          parent.children[pos].insertAdjacentElement("beforebegin", node1);
        }
      }

      let position = pos + 1;
      setCursorPosition(position);

      if (isAutoQuotes) {
        clearTimeout(quoteTimer);
        quoteTimer = setTimeout(() => {
          let string1 = String(getId().innerText);
          autoQuotesWorker.postMessage({
            string: string1,
          });
        }, 10);
      }
    } catch (e) {}
  };

  const removeSelected = () => {
    try {
      let selection = window.getSelection();
      if (selection && selection.toString() != "") {
        let range = selection.getRangeAt(0).toString();
        let word = range;

        let pos = cursorPosition();
        let parent = getId();
        let text = String(parent.innerText);

        let find = findSelectionIndexes(text, pos, word);
        let s = find.startIndex;
        let e = find.endIndex;

        let index = e;
        let f = () => {
          index--;
          if (index == s) {
            try {
              getId().children[s].remove();
            } catch (e) {}
          } else {
            try {
              getId().children[index].remove();
            } catch (e) {}
            f();
          }
        };
        f();
        setCursorPosition(s);
      }
    } catch (e) {}
  };

  const removeElement = () => {
    try {
      let parent = getId();
      let childrens = parent.children;
      let pos = cursorPosition();

      if (childrens.length > 0 && pos != 0) {
        let elem = childrens[pos - 1];
        parent.removeChild(elem);
      }
    } catch (e) {}
  };

  const noAction = (e) => {
    e.preventDefault();
    return false;
  };

  const detectKey = (e) => {
    let cKey = 67;
    let vKey = 86;
    let isArrow = false;

    setStringChange(e.keyCode);

    setTextCount(String(getId().innerText).trim().length);

    if ((e.ctrlKey || e.metaKey) && e.keyCode === vKey) {
      return false;
    }

    e.preventDefault();
    let selection = document.getSelection();

    if ((e.keyCode >= 112 && e.keyCode <= 123) || e.key === "Meta" || e.key === "ContextMenu") {
      e.preventDefault();
      return false;
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 65) {
      selectTextToClip();
      e.preventDefault();
      return true;
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === cKey) {
      e.preventDefault();
      copySelected();
      return true;
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 88) {
      e.preventDefault();
      cutSelected();
      return true;
    }

    if ((e.keyCode >= 112 && e.keyCode <= 123) || e.key === "Meta" || e.key === "ContextMenu") {
      e.preventDefault();
      return false;
    }
    if ((e.keyCode >= 65 && e.keyCode <= 95) || (e.keyCode >= 108 && e.keyCode <= 126)) {
      setTimeout(() => {
        createNode(e.key);
      }, 0);
    } else if (e.key == "Backspace") {
      if (e.repeat) {
        return false;
      } else {
        try {
          let text = window.getSelection().getRangeAt(0).toString();
          if (text != "") {
            removeSelected();
          } else {
            removeElement();
            try {
              removeColor(String(getId().innerText), getId());
            } catch (e) {}
          }
        } catch (e) {}
      }
    } else if (e.key == "Delete") {
      if (e.repeat) {
        return false;
      } else {
        let text = window.getSelection().getRangeAt(0).toString();

        if (text != "") {
          removeSelected();
        } else {
          removeByDelete();
          removeColor(String(getId().innerText), getId());
        }
      }
    } else if (e.code == "ArrowLeft") {
      selection.modify("move", "left", "character", "granularity");
      isArrow = true;
    } else if (e.code == "ArrowRight") {
      selection.modify("move", "right", "character", "granularity");
      isArrow = true;
    } else if (e.code == "ArrowUp") {
      selection.modify("move", "left", "line", "granularity");
      getId().scrollBy(0, -25);
      isArrow = true;
    } else if (e.code == "ArrowDown") {
      selection.modify("move", "right", "line", "granularity");
      getId().scrollBy(0, 25);
      isArrow = true;
    } else {
    }

    if (e.key) {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 65) {
      } else if (e.ctrlKey) {
      } else {
        try {
          let text = window.getSelection().getRangeAt(0).toString();
          if (text) {
            removeSelected();
          }
        } catch (e) {}
      }
    }

    let findChar = AllCharacterAndSymbols.find((a) => a == e.key);
    if (findChar != undefined) {
      setTimeout(() => {
        createNode(e.key);
      }, 0);
    }

    if (!isArrow) {
      clearTimeout(colorTimer);
      colorTimer = setTimeout(() => {
        applyFeature();
      }, 300);
    }
  };

  const addNodesInBetween = (index, ch, first = false) => {
    try {
      let parent = getId();
      const node = document.createElement("span");
      if (ch === " ") {
        node.innerHTML = "&nbsp;";
      } else {
        node.innerText = ch;
      }

      if (first) {
        parent.children[index].insertAdjacentElement("beforebegin", node);
      } else {
        parent.children[index].insertAdjacentElement("afterEnd", node);
      }
    } catch (e) {
      // console.log("exception ---- ", e);
    }
  };

  const removeByDelete = () => {
    try {
      let parent = getId();
      let strLength = String(parent.innerText).length;
      let childrens = parent.children;
      let pos = cursorPosition();
      let str = String(parent.innerText);

      if (pos <= 1 && str.length <= 1) {
        getId().removeChild(parent);
      } else {
        if (childrens.length > 0 && strLength !== pos) {
          let elem = childrens[pos];
          parent.removeChild(elem);
        }
      }
    } catch (e) {}
  };

  const permissions = () => {
    let r =
      (auth?.access == "owner" && !isShareWithMe) ||
      (auth?.access == "admin" && !isShareWithMe) ||
      (auth?.access == "user" && permission == 0 && !isShareWithMe) ||
      (auth?.access == "owner" && isShareWithMe) ||
      (auth?.access == "admin" && isShareWithMe) ||
      (auth?.access == "user" && isShareWithMe)
        ? socketData?.status == ""
          ? null
          : socketData?.status == "typing" &&
            auth?.user_id != socketData.typing_user_id &&
            socketData?.typing_user_id != 0 &&
            socketData?.string_id == currentActiveString?.id
          ? true
          : false
        : true;

    return r;
  };

  const revisionHistory = async () => {
    let res = await globalRequest(
      "post",
      KEYWORD_STRING.REVISION_HISTORY,
      {
        user_id: auth?.user_id,
        revision_id: currentActiveString?.revision_id,
      },
      {},
      true
    );

    if (res.data.status == 1) {
      let datas = { ...currentActiveString };
      setIsContentEnable(true);
      revisionCallback(datas);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: res.data.message,
          snackbarState: "success",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: res.data.message,
          snackbarState: "error",
        })
      );
    }
  };

  const selectedData = (data) => {
    if (data.length) {
      let sort = data.sort((a, b) => a.ind - b.ind);
      setSelectedWord(sort);
      let count = sort.map((a) => String(a.string).trim().length);
      setTextCount(count.reduce((partialSum, a) => partialSum + a));
    }
  };

  const putQuotesOnLast = () => {
    try {
      let str12 = String(getId().innerText);
      let split = str12.split(/AND|NOT|OR/g).pop();
      let lastIndex = str12.lastIndexOf(split);
      let globalLength = str12;

      let arr = [...split];
      let lastIndex2 = arr.length;

      let isCharAvail = /[a-zA-Z0-9]/.test(split);

      if (isCharAvail) {
        if (lastIndex + 1 == globalLength || lastIndex == globalLength) {
          return false;
        } else {
          let arrs = [...split.trim()];
          if (arrs[0] != '"' && arrs[0] != "(" && arrs[arrs.length - 1] != '"' && arrs[arrs.length - 1] != ")") {
            addNodesInBetween(lastIndex == 0 ? lastIndex : lastIndex, '"', lastIndex == 0 ? true : false);
            addNodesInBetween(lastIndex + lastIndex2, '"');
            applyFeature();
            setTimeout(() => {
              let strs = String(getId().innerText);
              setCursorPosition(strs.length);
            }, 150);
          }

          let strs = String(getId().innerText);
          currentStringCallback(strs);
          setTextCount(String(strs).trim().length);

          return true;
        }
      }
    } catch (e) {}
  };

  const pasteTextToEditor = (e) => {
    e.preventDefault();

    const text = window.getSelection().toString();

    let text1 = window.getSelection();
    let f = text1.getRangeAt(0).toString();
    let parent = getId();
    let str = String(parent.innerText);
    let poses = 0;

    if (f !== "") {
      removeSelected();
      let pos = cursorPosition();
      let paste = (e.clipboardData || e.originalEvent.clipboardData).getData("text");
      paste = replaceComma(paste);
      paste = addQuotes(String(paste));
      str = str.substring(0, pos) + ` ${paste} ` + str.substring(pos + f.length, str.length + 1);
      pos = pos + paste.length;
      poses = pos;
    } else {
      if (text != "") {
        getId().innerHTML = "";
      }

      let paste = (e.clipboardData || e.originalEvent.clipboardData).getData("text");
      paste = replaceComma(paste);
      paste = addQuotes(String(paste));

      let pos = cursorPosition();
      if (str.length != 0) {
        var output = [str.slice(0, pos), " " + paste + " ", str.slice(pos)].join("");
        pos = pos + paste.length;
        str = output;
      } else {
        pos = str.length;
        str = paste;
      }
      poses = pos + 2;
    }

    let html = createSpan(str);

    colorStringWork.postMessage({
      string: str,
    });

    parent.innerHTML = "";
    parent.innerHTML = html;

    setTimeout(() => {
      setCursorPosition(poses);
    }, 20);

    setTimeout(() => {
      applyFeature();
    }, 100);

    saved("typing");
    setTimeout(() => {
      saved("release");
    }, 2000);
    currentStringCallback(str);
  };

  return (
    <div className="table">
      <div className="table__textarea-div">
        <div className={isDeletedString ? "grayout-bg" : `${permissions() ? "grayout-bg" : ""}`}>
          {isDeletedString ? (
            <div className="no-string-data">
              <img src={booNegative} className="boo-sad-img" />
              <Body
                text={strings.UnfortunatelyThestringownerhasremovedyourcollaborationaccess_text}
                color={Colors.darkPurple}
              />
            </div>
          ) : null}
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ display: typeParam == "company" ? "block" : combos ? "none" : "block" }}
            placeholder={"Start typing your Boolean search here..."}
            onKeyDown={(e) => {
              if (trialData?.checkPay) {
                let number = 0;
                if (e.key == "Backspace") {
                  if (e.repeat) {
                    number += 1;
                  } else {
                    number = 0;
                  }
                }
                if (number == 1) {
                  dispatch(
                    setSnackbar({
                      snackbarOpen: true,
                      snackbarMessage: strings.backspaceHoldError,
                      snackbarState: "error",
                    })
                  );
                }
                if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {
                  return false;
                } else {
                  detectKey(e);
                }
                saved("typing");
                clearTimeout(saveTimer);
                saveTimer = setTimeout(() => {
                  putQuotesOnLast();
                  saved("release");
                }, 3000);
              } else {
                noAction(e);
                dispatch(
                  setSnackbar({
                    snackbarMessage: strings.dontHaveActivePlan,
                    snackbarOpen: true,
                    snackbarState: "error",
                  })
                );
              }
            }}
            spellCheck={false}
            contentEditable={isDeletedString ? false : permissions() ? false : isContentEnable}
            className={`textEditable editor-box`}
            id={id}
            ref={ref}
            onPaste={(e) => {
              if (trialData?.checkPay) {
                if (permissions()) {
                  e.preventDefault();
                  return;
                } else {
                  if (isContentEnable) {
                    pasteTextToEditor(e);
                  } else {
                    e.preventDefault();
                    return;
                  }
                }
              } else {
                e.preventDefault();
                dispatch(
                  setSnackbar({
                    snackbarMessage: strings.dontHaveActivePlan,
                    snackbarOpen: true,
                    snackbarState: "error",
                  })
                );
              }
            }}
          ></div>
          {typeParam == "keyword" ? combos ? <AdvanceViewCopyEditor selectedData={selectedData} /> : null : null}
          {auth?.access == "user" && permission == 1 && !isShareWithMe ? (
            <>
              <TypingView socketData={{}} type="view" />
            </>
          ) : socketData?.status == "typing" &&
            auth?.user_id != socketData.typing_user_id &&
            socketData?.typing_user_id != 0 &&
            socketData.string_id == currentActiveString?.id ? (
            <>
              <TypingView socketData={socketData} type="typing" />
            </>
          ) : (
            <CopySection
              currentActiveString={currentActiveString}
              revisionHistory={revisionHistory}
              copyTextToClip={copyTextToClip}
              comboCallback={(value) => {
                setCombos(value);
                if (!value) {
                  setSelectedWord([]);
                }
              }}
              textCount={textCount}
              includeQuotationCallback={(value) => {
                setIncludeQuotation(value);
              }}
              isTranslateModule={false}
              typingStatus={typing}
            />
          )}
          <div
            className="new-error-msg"
            style={isContentEnable && errorMsg ? { visibility: "visible" } : { visibility: "hidden" }}
          >
            <img style={{ height: "36px", marginRight: "auto", marginLeft: 10 }} src={booOn} />
            <div className="new-error-msg-content"> {errorMsg} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorView;
