import React, { Component }  from 'react';
import styled from 'styled-components/macro'
import * as Colors from './Colors'
import * as FontStyles from './FontStyles'

export const InputWrapper = styled.div`
  position: relative;
  margin: auto;
  text-align: center;
`;

const InputProvider = styled.input`
  border-radius: ${props => props.borderRadius};
  border-width: ${props => props.borderWidth};
  border-color: ${props => props.borderColor};
  border-style: ${props => props.borderStyle};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  placeholder: ${props => props.placeholder};
  margin: ${props => props.margin};
  min-height: ${props => props.minHeight};
  maxWidth: ${props => props.maxWidth};
  minWidth: ${props => props.minWidth};
  padding: ${props => props.padding};
  textAlign: ${props => props.textAlign};
  color: ${props => props.color};
  font-weight: ${props => FontStyles.getFontWeight(FontStyles.largeBody)};
  font-size: ${props => FontStyles.getFontSize(FontStyles.largeBody)};
  font-family: ${props => FontStyles.getFontFamily(FontStyles.largeBody)};
  width: ${props => props.width};
  type: ${props => props.type};
  helper-text: ${props => props.helperText};
  className: ${props => props.className};
  box-sizing: border-box;

  ::placeholder {
    font-weight: ${props => FontStyles.getFontWeight(FontStyles.largeBodyRegular)};
    font-size: ${props => FontStyles.getFontSize(FontStyles.largeBodyRegular)};
    font-family: ${props => FontStyles.getFontFamily(FontStyles.largeBodyRegular)};
    color: ${Colors.darkGray};
  }

  :focus {
    outline: none;
  }
  `; 
  
InputProvider.defaultProps = {
  borderRadius: '8px',
  borderWidth: '0px',
  borderColor: Colors.softGray,
  borderStyle: 'solid',
  placeholder: 'Placeholder',
  margin: '0px',
  minHeight: '40px',
  maxWidth: '200px',
  minWidth: '150px',
  padding: '7px 16px',
  textAlign: 'left',
  color: Colors.nightGray,
  width: '100%'
}


const InputSearchProvider = styled.input`
  border-radius: ${props => props.borderRadius};
  border-width: ${props => props.borderWidth};
  border-color: ${props => props.borderColor};
  border-style: ${props => props.borderStyle};
  box-shadow: none;
  placeholder: ${props => props.placeholder};
  margin: ${props => props.margin};
  min-height: ${props => props.minHeight};
  maxWidth: ${props => props.maxWidth};
  minWidth: ${props => props.minWidth};
  padding: ${props => props.padding};
  textAlign: ${props => props.textAlign};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  font-weight: ${props => FontStyles.getFontWeight(FontStyles.body)};
  font-size: ${props => FontStyles.getFontSize(FontStyles.body)};
  font-family: ${props => FontStyles.getFontFamily(FontStyles.body)};
  width: ${props => props.width};
  type: ${props => props.type};
  box-sizing: border-box;

  ::placeholder {
    font-weight: ${props => FontStyles.getFontWeight(FontStyles.body)};
    font-size: ${props => FontStyles.getFontSize(FontStyles.body)};
    font-family: ${props => FontStyles.getFontFamily(FontStyles.body)};
    color: ${Colors.darkGray};
  }

  :focus {
    outline: none;
  }
  `; 
  
  InputSearchProvider.defaultProps = {
  borderRadius: '8px',
  borderWidth: '0px',
  borderColor: Colors.softGray,
  borderStyle: 'solid',
  backgroundColor: '#F5F6FA',
  placeholder: 'Placeholder',
  margin: '0px',
  minHeight: '40px',
  maxWidth: '200px',
  minWidth: '150px',
  padding: '7px 16px',
  textAlign: 'left',
  color: Colors.nightGray,
  width: '100%'
}



const TextAreaProvider = styled.textarea`
  border-radius: ${props => props.borderRadius};
  border-width: ${props => props.borderWidth};
  border-color: ${props => props.borderColor};
  border-style: ${props => props.borderStyle};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  placeholder: ${props => props.placeholder};
  margin: ${props => props.margin};
  min-height: ${props => props.minHeight};
  maxWidth: ${props => props.maxWidth};
  minWidth: ${props => props.minWidth};
  padding: ${props => props.padding};
  textAlign: ${props => props.textAlign};
  color: ${props => props.color};
  font-weight: ${props => FontStyles.getFontWeight(FontStyles.largeBody)};
  font-size: ${props => FontStyles.getFontSize(FontStyles.largeBody)};
  font-family: ${props => FontStyles.getFontFamily(FontStyles.largeBody)};
  width: ${props => props.width};
  type: ${props => props.type};
  box-sizing: border-box;

  ::placeholder {
    font-weight: ${props => FontStyles.getFontWeight(FontStyles.largeBodyRegular)};
    font-size: ${props => FontStyles.getFontSize(FontStyles.largeBodyRegular)};
    font-family: ${props => FontStyles.getFontFamily(FontStyles.largeBodyRegular)};
    color: ${Colors.darkGray};
  }

  :focus {
    outline: none;
  }
  `; 
  
  TextAreaProvider.defaultProps = {
    borderRadius: '4px',
    borderWidth: '0px',
    borderColor: Colors.softGray,
    borderStyle: 'solid',
    placeholder: 'Placeholder',
    margin: '0px',
    minHeight: '140px',
    maxWidth: '200px',
    minWidth: '150px',
    padding: '7px 16px',
    textAlign: 'left',
    color: Colors.nightGray,
    width: '100%'
}

/**
 * Represents a regular textfield with 
 * font size: 16px
 * font name: Poppins
 * font weight: Regular
 * padding: 12px 16px
 */
export function Textfield(props) {
  return <InputProvider
            type={props.type ? props.type : "text"}
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            padding={props.padding ? props.padding : '12px 16px'} 
            margin={props.margin}
            flexGrow={props.flexGrow}
            color={Colors.nightGray}
            borderRadius={props.borderRadius ? props.borderRadius : '4px'}
            borderWidth={'0px'}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            autoFocus={props.autoFocus}
            id={props.id}
            required={props.required}
            helperText={props.helperText}
            className={props.className}
            style={props.style}
            ref={props.ref}
            onKeyDown={props.onKeyDown}
            >
              {props.text}
          </InputProvider>
}


/**
 * Represents a regular textsearchfield with 
 * font size: 14px
 * font name: Poppins
 * font weight: Midium
 * padding: 12px 16px
 */
 export function TextSearchfield(props) {
  return <InputSearchProvider
            type={props.type ? props.type : "text"}
            value={props.value}
            placeholder={props.placeholder}
            padding={'8px 16px 8px 40px'} 
            margin={props.margin}
            flexGrow={props.flexGrow}
            color={Colors.nightGray}
            id={props.id?props.id:""}
            borderWidth={'1px'}
            borderColor={Colors.whiteMidGray}
            theme={FontStyles.body}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            autoFocus={props.autoFocus}
            onKeyDown={props.onKeyDown}
            onKeyUp={props.onKeyUp}
            >
              {props.text}
          </InputSearchProvider>
}

/**
 * Represents a regular textfield(textarea) with 
 * font size: 16px
 * font name: Poppins
 * font weight: Regular
 * padding: 12px 16px
 */
 export function TextAreafield(props) {
  return <TextAreaProvider
          type={props.type ? props.type : "text"}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          padding={'12px 16px'} 
          margin={props.margin}
          flexGrow={props.flexGrow}
          color={Colors.nightGray}
          borderRadius={'4px'}
          borderWidth={'0px'}
          textAlign={props.width}
          width={props.width}
          fadeIn={props.fadeIn}
          onClick={props.onClick}
          mobileWidth={props.mobileWidth}
          mobileMargin={props.mobileMargin}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          className={props.className}
          style={props.style}
          >
            {props.text}
          </TextAreaProvider>
}